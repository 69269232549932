import { createTheme } from "@mui/material/styles";

export default createTheme({

  palette: {
    mode: "dark",
    primary: {
      main: "#171721",
      contrastText: "#ffffffb3"
    },
    common: {
      white: "#ffffffb3",
      black: "#000000b3"
    },
    secondary: {
      main: "#ea2e32",
      contrastText: "#ffffffb3"
    },
    text: {
      secondary: "#ffffffb3",
      primary: "#ffffffb3"
    },
    white: {
      main: "#ffffffb3",
      contrastText: "#ffffffb3"
    },
    info: {
      main: "#1877f2",
      contrastText: "#ffffffb3"
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e2b",
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#171721",
        }
      }
    }
  }

  // typography: {
  //   fontFamily: ['Almarai']
  // }
})
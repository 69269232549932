import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hooks, constants } from "../../../utils";
import {
  GuildButtons,
  GuildContainer,
  GuildInfo,
  GuildName,
  GuildOverview,
  GuildsListContainer,
  GuildsWrapperContainer,
  GuildsWrapperTitle,
} from "./styled.js";

import {
  LinkButton,
  Loading,
  PopupWindow,
  useAuth,
  useGuilds,
} from "../../../components";
import { Avatar, Button } from "@mui/material";
import NoImagePlaceholder from "../../../assets/NoImagePlaceholder.png";

export default function Server() {
  const [guildsList, setGuildsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { guilds } = useGuilds();
  const Navigate = useNavigate();

  useEffect(() => {
    const list = [];
    for (const node in guilds) {
      for (const guild of guilds[node]) {
        if (node === "included") {
          guild.included = true;
          list.push(guild);
        } else list.push(guild);
      }
    }
    setGuildsList(list);
    setLoading(false);
  }, [guilds, Navigate]);

  const onInvite = (guild) => {
    const popup = PopupWindow(
      `${constants.ExternalUri.INVITE}&guild_id=${guild.id}`
    );
    if (popup) {
      const timer = setInterval(() => {
        if (popup.closed) {
          Navigate(guild.id);
          if (timer) clearInterval(timer);
        }
      }, 500);
    }
  };

  if (loading) return <Loading />;
  else
    return (
      <GuildsWrapperContainer>
        <GuildsWrapperTitle>Select a server</GuildsWrapperTitle>
        <GuildsListContainer>
          {guildsList.map((prop, index) => (
            <GuildContainer
              initial={{ opacity: 0 }}
              whileInView={{ opacity: [0, 0, 1], y: [100, 50, 0] }}
              transition={{ duration: 0.5 }}
              key={index}
              bg={hooks.GuildIconGenerator(prop)}
            >
              <GuildOverview>
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={hooks.GuildIconGenerator(prop) || NoImagePlaceholder}
                  alt={prop.name}
                />
              </GuildOverview>
              <GuildInfo>
                <GuildName>{prop.name}</GuildName>
                <GuildButtons>
                  {prop.included ? (
                    <LinkButton variant="contained" color="info" to={prop.id}>
                      Dashboard
                    </LinkButton>
                  ) : (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => onInvite(prop)}
                    >
                      Setup
                    </Button>
                  )}
                </GuildButtons>
              </GuildInfo>
            </GuildContainer>
          ))}
        </GuildsListContainer>
      </GuildsWrapperContainer>
    );
}

import React, { useEffect } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { Sidebar, useAuth } from "../../components";
import RoutesList from "./routes";
import {
  Container,
  ContentContainer,
  SidebarContainer,
  Wrapper,
} from "./styled";

export default function Admin() {
  const navigation = useNavigate();
  const { user } = useAuth();

  console.log("Admin", user);

  useEffect(() => {
    if (window.location.hostname !== "localhost") return navigation("/");
    if (user.id !== process.env.REACT_APP_API_ADMIN_ID) return navigation("/");
  }, [user]);

  return (
    <Container>
      <Wrapper>
        <SidebarContainer>
          <Sidebar routes={RoutesList} />
        </SidebarContainer>
        <ContentContainer>
          <Routes>
            {RoutesList.map((route) => (
              <Route element={route.element} path={route.path} />
            ))}
          </Routes>
          <Outlet />
        </ContentContainer>
      </Wrapper>
    </Container>
  );
}

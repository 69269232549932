import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import Category from "./Category";
import Command from "./Command";
import {
  CommandsView,
  CommandsContainer,
  CommandsInfoBox,
  CommandsInfoBoxTitle,
  CommandsInfoBoxSubject,
  InfoBoxContainer,
} from "./styled";
import { GET } from "../../utils";
import Loading from "../../components/Loading/Loading";
// import '../../config/firebaseAuth'

export default function Commands() {
  const Navigate = useNavigate();
  const [query] = useSearchParams();
  const [commands, setCommands] = useState([]);
  const [loading, setLoading] = useState(true);
  // const OptionsType = {
  //   5: "True", //Boolean
  //   7: "general", //Channel
  //   4: "1", //Integer
  //   9: "@Mina", //Mentionable
  //   10: "5", //Number
  //   8: "@Boss", //Role
  //   3: "Hello", //String
  // }

  useEffect(() => {
    if (!query.get("category")) {
      Navigate("?category=all");
    }
    (async () => {
      const data = await GET.ClientCommands((snap) => {
        setCommands(snap.val());
      });
      setCommands(data);
      setLoading(false);
    })();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <CommandsContainer>
        <InfoBoxContainer>
          {/* <CommandsInfoBox>
            <CommandsInfoBoxTitle>
              <i className="fa-solid fa-circle-exclamation"></i> Alert!
            </CommandsInfoBoxTitle>
            <CommandsInfoBoxSubject>
              We are working as fast as we can to move all bot commands to new
              discord feature "Slash Commands" ( / ), not all commands added
              here yet, try everything on your own server by typing "/help"
            </CommandsInfoBoxSubject>
          </CommandsInfoBox> */}
          <CommandsInfoBox>
            <CommandsInfoBoxTitle>
              <i className="fa-solid fa-star"></i> New!
            </CommandsInfoBoxTitle>
            <CommandsInfoBoxSubject>
              You can now setup a colors role for your server, you can check it
              by typing "/colors", "/setup colors" or "/color" in your server
              chat
            </CommandsInfoBoxSubject>
          </CommandsInfoBox>
        </InfoBoxContainer>
        <CommandsView>
          <Category commands={commands} />
          <Command commands={commands} />
        </CommandsView>
      </CommandsContainer>
    );
}

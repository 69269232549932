const Routes = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links',
        icon: 'fas fa-home',
        disable: false,
        path: 'none'

    },
    {
        title: 'Commands',
        url: '/commands?category=all',
        cName: 'nav-links',
        icon: 'fas fa-th-large',
        disable: false,
        path: 'none'

    },
    // {
    //     title: 'Tutorials',
    //     url: '/tutorials',
    //     cName: 'nav-links',
    //     icon: 'fas fa-th-large',
    //     disable: false,
    //     path: 'none'

    // },
    // {
    //     title: 'Store',
    //     url: "/store",
    //     cName: 'nav-links',
    //     disable: true,
    //     icon: 'fab fa-discord',
    // },
    {
        title: 'Market',
        url: '/market',
        cName: 'nav-links',
        icon: 'fas fa-gem',
        disable: true,
        path: 'none'
    },
    {
        title: 'Premium',
        url: '/premium',
        cName: 'nav-premium',
        icon: 'fas fa-gem',
        disable: true,
        path: 'none'
    },
]

export default Routes;
import React from 'react'
import { SuggestionsContainer, SuggestionsHeader } from './styled'
export default function Suggest() {
    return (
        <SuggestionsContainer>
            <SuggestionsHeader>
                We'd love to hear from you and what you're thinking about us.
                If you have idea and
            </SuggestionsHeader>
        </SuggestionsContainer>
    )
}

import React, { useState } from "react";
import Routes from "./Routes";
import { MainLogo } from "../../assets";
import { NavLink } from "react-router-dom";
import {
  Logo,
  NavbarContainer,
  NavbarRow,
  NavbarListContainer,
  MobileIcon,
} from "./styled";
import LoginButton from "../LoginButton";
import { Button } from "@mui/material";

function Navbar() {
  const [menu, setMenu] = useState(false);
  const handleClick = () => {
    setMenu(!menu);
  };

  return (
    <NavbarContainer>
      <NavbarRow>
        <Logo href="/">
          <img src={MainLogo} alt="" />
          <h1>KEEN</h1>
        </Logo>
        <MobileIcon onClick={handleClick}>
          <i className={menu ? "fas fa-times" : "fas fa-bars"}></i>
        </MobileIcon>
        <NavbarListContainer className={menu ? "mobile-view" : null}>
          {Routes.map((item, index) => {
            return (
              <Button color="white" key={index} disabled={item.disable}>
                <NavLink className={item.cName} to={item.url}>
                  <span>{item.title}</span>
                </NavLink>
              </Button>
            );
          })}
          <LoginButton />
        </NavbarListContainer>
      </NavbarRow>
    </NavbarContainer>
  );
}

export default Navbar;

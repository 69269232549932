import styled from "styled-components";
import { constants } from "../../utils";

export const NavbarContainer = styled.nav`
    height: 80px;
    font-size: 16px;
    background-color: var(--primary-light);
    width: 100%;
    position: relative;
`

export const NavbarRow = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1240px;
    margin: 0px auto;
    padding: 18px 40px;
`

export const Logo = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    img { 
        max-height: 2.5rem;
        border-radius: 50%;
        margin: 0rem 0.3rem;
    }
    h1{
        opacity: .7;
        color: var(--white);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
`
export const MobileIcon = styled.div`
    display: none;
    color: var(--white);
    font-size: 20px;
    font-size: 1.8rem;
    @media ${constants.Device.MOBILE} {
        display: block  
    }
`


export const NavbarListContainer = styled.div`
    display: flex;
    align-items: center;
    transition: all .3s linear;
    gap: 1rem;
    &.mobile-view {
        left: 0; 
    } 
    @media ${constants.Device.MOBILE} {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        position: absolute;
        border-right: 2px solid var(--light-gray);
        height: 100vh;
        width: 70%;
        top: 80px;
        left: -100%;
        z-index: 9999;
        background-color: var(--primary-light);
    }
ul {
    @media ${constants.Device.MOBILE} {
        padding: 1rem;
        flex-direction: column;
        font-size: 1rem;
    }
    list-style: none;
    display: flex;
    margin-inline-end: 16px;
    li {
        &.disable {
            cursor: not-allowed;
            pointer-events: none;
            text-decoration: line-through;
        }
        @media ${constants.Device.MOBILE} {
            padding: 1rem 0;
        }
        a {
            &.nav-premium {
               color: var(--gold);
               background-color : var(--dark-gold);
               :hover { 
                color: var(--gold);
                background-color: var(--dark-gold);
            }
            } 
            &.nav-login {
               color: var(--secondary);
               background-color : var(--dark-red);
               :hover { 
                color: var(--secondary);
                background-color: var(--dark-red);
            }
            } 
            padding: .3rem;
            text-decoration: none;
            color: var(--light-gray);
            margin: 0 10px;
            transition: all .2s linear;
            i {
                margin-right: 0.3rem;
            }
            :hover { 
                box-shadow: 0 2px var(--light-gray);
            }
        }
        .active-page {
            box-shadow: 0 2px var(--light-gray);
            /* color: var(--secondary); */
            /* background-color: var(--dark-red); */
        }
    }
}
`

// Login Icon

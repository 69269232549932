const links = {
  invite: "https://discord.com/api/oauth2/authorize?client_id=959080899650146314&permissions=1945627743&scope=bot%20applications.commands",
  // invite: 'https://discord.com/api/oauth2/authorize?client_id=597444503242276874&permissions=8&scope=bot%20applications.commands',
  support_server: 'https://discord.com/invite/aVW2VcJ',
  vote: 'https://top.gg/bot/597444503242276874/vote',
  donate: 'https://www.patreon.com/keenbot',
  bugz: 'https://top.gg/bot/801420138607804436',
  login: "http://localhost:3002/auth/discord",
  osmx: "https://osmx.me/"
}

export default links;
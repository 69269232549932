const ExternalUri = {
  INVITE: "https://discord.com/api/oauth2/authorize?client_id=959080899650146314&permissions=1945627743&redirect_uri=https%3A%2F%2Fwww.keenbot.ml&scope=bot%20applications.commands",
  SUPPORT: 'https://discord.com/invite/aVW2VcJ',
  VOTE: 'https://top.gg/bot/597444503242276874/vote',
  DONATE: 'https://www.patreon.com/keenbot',
  LOGIN: "http://localhost:3002/auth/discord",
  OSMX: "https://osmx.me/"
}

// const size = {
//   mobileS: '320px',
//   mobileM: '375px',
//   mobileL: '425px',
//   tablet: '768px',
//   laptop: '1024px',
//   laptopL: '1440px',
//   desktop: '2560px'
// }
const Device = {
  MOBILE: `(max-width: 768px)`,
  // laptop: `(max-width: ${size.laptop})`,
  // laptopL: `(max-width: ${size.laptopL})`,
  // desktop: `(max-width: ${size.desktop})`,
  // desktopL: `(max-width: ${size.desktop})`
};


const constants = {
  ExternalUri,
  Device
}


export default constants
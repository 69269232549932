import React from 'react'
import styled from 'styled-components'
import coin_image from '../../utils/assets/keenbot_coin.png'
function Coin({ size = 50 }) {

  const Image = styled.img`
    width: ${size}px;
    transition: all .2s linear;
    :hover {
      transform: scale(1.1);
    }
  `
  return (
    <Image src={coin_image} alt="" />
  )
}
export default Coin

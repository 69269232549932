import styled from "styled-components";


export const Container = styled.div`
  height: calc(100vh - 80px);
  position: relative;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 2rem;
  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--white)
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 1rem;
`

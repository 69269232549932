import { useState, useEffect, useRef } from "react";
import screens from "./screens";
import {
  UsedGuildContext,
  Loading,
  useAuth,
  useGuilds,
} from "../../components";
import {
  useNavigate,
  useParams,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { hooks } from "../../utils";

import Sidebar from "./sidebar";
import { DashboardViews, DashboardViewsContainer } from "./styled";
import MainRoutes from "..";
import { toast } from "react-toastify";

export default function Dashboard() {
  const [guild, setGuild] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { guilds } = useGuilds();
  const { id } = useParams();
  const timerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      if (timerRef.current) clearTimeout(timerRef.current);
      // timerRef.current = setTimeout(() => {
      //   navigate("/");
      //   toast.error("You need to be logged in to access this page");
      // }, 3000);
    } else {
      if (timerRef.current) clearTimeout(timerRef.current);
      const currentGuild = hooks.isGuildAdmin(guilds, id);
      if (currentGuild) {
        setGuild(currentGuild);
        setLoading(false);
      }
    }
  }, [id, user, guilds]);

  // useEffect(() => {
  //   if (!user)
  //     timerRef.current = setTimeout(() => {
  //       return Navigate("/");
  //     }, 5000);
  //   else clearTimeout(timerRef.current);

  //   const CurrentGuild = hooks.IsGuildModerator(guilds, id);
  //   if (CurrentGuild) {
  //     setGuild(CurrentGuild);
  //     setLoading(false);
  //     document.title = `KEEN - ${guild.name}`;
  //   } else Navigate("/dashboard");
  // }, [user, guild, guilds, id]);

  if (loading) return <Loading />;
  else
    return (
      <UsedGuildContext.Provider value={{ guild, setGuild }}>
        <DashboardViewsContainer>
          <Sidebar />
          <DashboardViews>
            <Routes>
              {screens.map((item) => {
                return item.routes.map((prop, index) => (
                  <Route
                    {...prop?.options}
                    key={index}
                    path={prop.path}
                    element={prop.element}
                  />
                ));
              })}
              {MainRoutes.dashboard.map((prop, index) => (
                <Route path={prop.path} element={prop.element} key={index} />
              ))}
            </Routes>
            <Outlet />
          </DashboardViews>
        </DashboardViewsContainer>
      </UsedGuildContext.Provider>
    );
}

import React from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { Container, Form, Wrapper } from "./styled";
import { Button, TextField } from "@mui/material";

export default function Report() {
  function sendEmail(e) {
    e.preventDefault();
    toast.success("Your message has been sent successfully");
    emailjs
      .sendForm(
        "service_d3itspw",
        "template_pjkfyvh",
        e.target,
        "user_9NXJK1vjN75yc1MBmMfng"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <Container>
      <Wrapper>
        <h2>Report a Bug</h2>
        <Form onSubmit={sendEmail}>
          <TextField
            placeholder="Name#0000"
            label="Your Discord Tag"
            variant="filled"
            color="secondary"
            name="name"
            fullWidth
            autoComplete="username"
            type="text"
          />
          <TextField
            fullWidth
            placeholder="example@exapmle.com"
            label="Email"
            variant="filled"
            color="secondary"
            name="email"
            type="email"
            required
            autoComplete="email"
          />
          <TextField
            fullWidth
            multiline
            required
            rows={7}
            label="Please write your report below."
            variant="filled"
            color="secondary"
            name="message"
            type="text"
          />
          <Button type="submit" variant="contained" color="secondary">
            Send Report
          </Button>
          <input type="hidden" name="contact_number" required />
          {/* <input type="hidden" name="contact_number" required />
          <label>Your Discord Tag</label>
          <input
            className="name"
            type="text"
            name="name"
            placeholder="@user#0000"
            required
          />
          <label>Email</label>
          <input
            className="email"
            type="email"
            name="email"
            placeholder="Your E-mail"
            required
          />
          <label>Please write your report below.</label>
          <textarea className="message" name="message" required />
          <input className="submit" type="submit" value="Send" /> */}
        </Form>
      </Wrapper>
    </Container>
  );
}

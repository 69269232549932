import React, { Component } from "react";
import Routes from "./routes";
import {
  FooterBody,
  Container,
  LinkElement,
  Links,
  LinksGroup,
  Rights,
  Svg,
} from "./styled";
import URL from "../../utils/url";

export default class Footer extends Component {
  render() {
    return (
      <Container>
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270">
          <path
            d="M0,192L48,186.7C96,181,192,171,288,192C384,213,480,267,576,250.7C672,235,768,149,864,128C960,107,1056,149,1152,165.3C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </Svg>
        <FooterBody>
          <Rights>
            <h1>KEEN</h1>
            <span>
              Copyright © 2020 - {new Date().getFullYear()}{" "}
              <a rel="noreferrer" target="_blank" href={URL.osmx}>
                OSMX
              </a>
            </span>
          </Rights>
          <Links>
            {Routes.map((prop, index) => (
              <div key={index}>
                <span>{prop.header}</span>
                <LinksGroup>
                  {prop.routes.map((route, key) => (
                    <LinkElement
                      key={key}
                      to={route.url}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      {route.name}
                    </LinkElement>
                  ))}
                </LinksGroup>
              </div>
            ))}
          </Links>
        </FooterBody>
      </Container>
    );
  }
}

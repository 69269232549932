import styled from "styled-components";

export const StoreContanier = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`
export const StoreTitle = styled.div`
  color: var(--white);
  margin: 2rem;
`

export const PackagesContanier = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 5rem;
`

export const Package = styled.div`
  margin-top: 2rem;
  background-color: var(--primary-light);
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  color: var(--light-gray);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
`

export const PackageHeader = styled.div`
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  height: 50px;
`
export const Amount = styled.div`
`
export const Extra = styled.div`
  font-size: .9rem;
`

export const PackagePrice = styled.div`
  font-size: 2rem;
`
export const PurchaseButton = styled.button`
  border: none;
  padding: .7rem 2.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all .2s;
  :hover, :focus {
    background-color: var(--gold);
  }
`

export const Info = styled.ul`
  margin-top: 2rem;
  color: var(--light-gray);
  /* list-style: ; */
`
import { useContext } from "react";
import GuildsContext from "../Context/GuildsContext";

/**
 * 
 * @returns {GuildsContext} guilds, setGuilds
 */

export default function useGuilds() {
  return useContext(GuildsContext);
}
import React from "react";
import { ScreenSection } from "../screens.style";
import { CategoriesContainer, Category, Head, Body, Icon } from "./styled";
import screens from "..";

export default function Overview() {
  return (
    <ScreenSection>
      <CategoriesContainer>
        {screens
          .filter((x) => !x.hidden)
          .map((prop) => {
            return prop.routes.map((route, index) => (
              <Category key={index} to={`${route.path}`}>
                <Head>
                  <Icon>{route.icon}</Icon>
                  <p>{route.name}</p>
                </Head>
                <Body>{route.description}</Body>
              </Category>
            ));
          })}
      </CategoriesContainer>
    </ScreenSection>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Wrapper,
  Container,
  ScreenHeader,
  ScreenHeaderContent,
  ComponentContainer,
  ComponentHeader,
  ActiveButton,
  ComponentTitle,
} from "./styled";
import screens from ".";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Checkbox,
  IconButton,
  ListItemText,
  Slider,
  Switch,
  TextField,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { toast } from "react-toastify";

export function ScreenContainer({
  children,
  name,
  description,
  button = false,
  checked,
  handleChange,
  onRefresh,
}) {
  const { pathname } = useLocation();
  const current_screen = pathname.split("/").pop();
  const [current, setCurrent] = useState({});

  useEffect(() => {
    screens.forEach((prop) => {
      prop.routes.forEach((r) => {
        if (r.path.toLowerCase() === current_screen) return setCurrent(r);
      });
    });
  }, [current_screen]);

  return (
    <Container>
      <ScreenHeader>
        <ScreenHeaderContent>
          <h2>
            <IconButton
              title="Refresh"
              onClick={() => {
                toast.info("Refreshed!", {
                  autoClose: 1000,
                });
                onRefresh && onRefresh();
              }}
            >
              <RestartAltIcon />
            </IconButton>
            {name || current.name}
          </h2>
          {button && (
            <ActiveButton>
              {checked ? "Active" : "Inactive"}
              <Switch
                name="state"
                color="success"
                checked={checked}
                onChange={handleChange}
              />
            </ActiveButton>
          )}
        </ScreenHeaderContent>
        <p>{description || current.description}</p>
      </ScreenHeader>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}

export function SelectView({
  title,
  description,
  options = [],
  onChange,
  value,
  label,
  withCheckbox = false,
  leftHeader,
  ...props
}) {
  const id = Math.random().toString(36).substring(7);
  return (
    <ComponentContainer>
      <ComponentHeader>
        <ComponentTitle>
          <h4>{title}</h4>
          {description && <p>{description}</p>}
        </ComponentTitle>
        {leftHeader}
      </ComponentHeader>
      <FormControl fullWidth variant="filled" color="info">
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          {...props}
          labelId={id}
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={onChange}
        >
          {options?.length !== 0 &&
            options?.map((option, index) =>
              withCheckbox ? (
                <MenuItem key={option?.value} value={option?.value}>
                  <Checkbox checked={value?.indexOf(option?.value) > -1} />
                  <ListItemText primary={option?.label} />
                </MenuItem>
              ) : (
                <MenuItem key={index} value={option?.value}>
                  {option?.label}
                </MenuItem>
              )
            )}
        </Select>
      </FormControl>
    </ComponentContainer>
  );
}
export function TextFieldView({
  title,
  description,
  options = [],
  onChange,
  value,
  leftHeader,
  label,
  ...props
}) {
  return (
    <ComponentContainer>
      <ComponentHeader>
        <ComponentTitle>
          <h4>{title}</h4>
          {description && <p>{description}</p>}
        </ComponentTitle>
        {leftHeader}
      </ComponentHeader>
      <TextField
        {...props}
        fullWidth
        variant="filled"
        color="info"
        label={label}
        value={value}
        onChange={onChange}
      />
    </ComponentContainer>
  );
}
export function SliderView({
  title,
  description,
  onChange,
  value,
  label,
  ...props
}) {
  return (
    <ComponentContainer>
      <ComponentHeader>
        <h4>{title}</h4>
        {description && <p>{description}</p>}
      </ComponentHeader>
      <Slider
        {...props}
        value={value}
        defaultValue={0}
        onChange={onChange}
        valueLabelDisplay="on"
      ></Slider>
    </ComponentContainer>
  );
}
export function EmptyView({ title, description, children }) {
  return (
    <ComponentContainer>
      <ComponentHeader>
        <h4>{title}</h4>
        {description && <p>{description}</p>}
      </ComponentHeader>
      {children}
    </ComponentContainer>
  );
}

import styled from "styled-components";
import { constants } from "../../utils";

export const DashboardViewsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 4fr;

  @media ${constants.Device.MOBILE} {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const DashboardViews = styled.div`
  width: 100%;
  padding: 2rem;
  min-height: calc(100vh - 80px);
`
import React, { useEffect } from "react";
import styled from "styled-components";
import { Loading } from "../../components";

export default function LoginRedirect() {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 2000);
  }, []);

  return (
    <LoadContainer>
      <Loading />
    </LoadContainer>
  );
}

const LoadContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary);
`;

function GuildIconGenerator(guild) {
  let format = "png"
  if (guild?.icon) {
    if (guild.icon.startsWith("a_")) format = "gif"
    return `https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.${format}`;
  } else {
    return null;
  }
}

function UserAvatarGenerator(user) {
  let format = "png"
  if (user?.details.avatar) {
    if (user?.details.avatar.startsWith("a_")) format = "gif"
    return `https://cdn.discordapp.com/avatars/${user?.id}/${user?.details.avatar}.${format}`;
  }
  else return `https://better-default-discord.netlify.app/Icons/Solid-Yellow.png`
}

function isGuildAdmin(guilds, guildId) {
  if (guilds?.included) {
    return guilds.included.find(g => g.id === guildId);
  }
  return null;
}


const hooks = {
  GuildIconGenerator,
  UserAvatarGenerator,
  isGuildAdmin
}

export default hooks;
import { ref, uploadBytesResumable, getStorage, getDownloadURL, deleteObject } from 'firebase/storage'

import { getDatabase, ref as dbRef, get, onValue, remove, set } from "firebase/database";


export const uploadFile = async (file, path, { onComplete, onProgress }) => {
  try {
    if (!file) return;
    const storage = getStorage();
    const metadata = { contentType: "image/jpeg" };
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTask.on("state_changed", (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      onProgress(progress)
    }, (e) => {
      console.log(e)
    }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then(onComplete)
    })

  } catch (error) {
    console.log(error)
  }
}

export const deleteFile = async (path) => {
  try {
    const storage = getStorage();
    const desertRef = ref(storage, path);
    return deleteObject(desertRef)
  } catch (error) {
    console.log(error)
  }
}

export const setNewItem = (data) => {
  try {
    const db = getDatabase();
    const dataRef = dbRef(db, `items/${data.id}`);
    return set(dataRef, data)
  } catch (error) {
    console.log(error)
  }
}

export const deleteItem = (id) => {
  try {
    const db = getDatabase();
    const dataRef = dbRef(db, `items/${id}`);
    return remove(dataRef)
  } catch (error) {
    console.log(error)
  }
}

export const getItemsList = async (onChange) => {
  const db = getDatabase();
  const dataRef = dbRef(db, "items")
  const item = await get(dataRef);
  onValue(dataRef, onChange);
  return item.val()
}
const URL = {
    invite: "https://discord.com/api/oauth2/authorize?client_id=959080899650146314&permissions=1945627743&redirect_uri=https%3A%2F%2Fwww.keenbot.ml&scope=bot%20applications.commands",
    // invite: 'https://discord.com/api/oauth2/authorize?client_id=597444503242276874&permissions=8&scope=bot%20applications.commands',
    support_server: 'https://discord.com/invite/aVW2VcJ',
    vote: 'https://top.gg/bot/597444503242276874/vote',
    donate: 'https://www.patreon.com/keenbot',
    bugz: 'https://top.gg/bot/801420138607804436',
    login: "http://localhost:3002/api/auth/discord",
    osmx: "https://osmx.me/"
}

export default URL
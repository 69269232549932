import styled from "styled-components";



export const Container = styled.div`
  min-height: 100vh;
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
`
export const SidebarContainer = styled.div``
export const ContentContainer = styled.div`
  padding: 1rem 1rem 0 0;
`
const packges = [
  {
    price: 2.99,
    amount: 1000,
    extra: 0,
  },
  {
    price: 5.99,
    amount: 2500,
    extra: 50,
  },
  {
    price: 11.99,
    amount: 5000,
    extra: 200,
  },
  {
    price: 49.99,
    amount: 20000,
    extra: 1500,
  },
  {
    price: 79.99,
    amount: 100000,
    extra: 5000,
  },
]

export default packges;
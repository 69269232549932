import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
  gap: 1rem;

  h2 {
    font-size: 15em;
    font-weight: bold;
    text-shadow: 0 5px var(--secondary);
  }
`

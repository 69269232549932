import React, { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "./Hooks/useAuth";
import Loading from "./Loading/Loading";

export default function Protected({ children, requiredUser }) {
  const [loading, setLoading] = React.useState(true);
  const { user } = useAuth();
  const timer = useRef(null);

  useEffect(() => {
    try {
      if (timer.current) clearTimeout(timer.current);
      if (requiredUser && user) return setLoading(false);
      timer.current = setTimeout(() => {
        setLoading(false);
        toast.error("You need to be logged in to access this page");
        clearTimeout(timer.current);
      }, 5000);

      return () => clearTimeout(timer.current);
    } catch (error) {
      setLoading(false);
    }
  }, [user]);

  if (loading) return <Loading />;
  else return user ? children : <Navigate to="/" replace />;
}

import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Container, Route, Wrapper } from "./styled";

export default function Sidebar({ routes }) {
  return (
    <Container>
      <Wrapper>
        {routes.map((route, index) => (
          <Link key={index} to={route.path}>
            <Button fullWidth variant="contained" color="primary">
              {route.name}
            </Button>
          </Link>
        ))}
      </Wrapper>
    </Container>
  );
}

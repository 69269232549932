import { useContext } from "react";
import UsedGuildContext from "../Context/UsedGuildContext";

/**
 * 
 * @returns {UsedGuildContext} guild, setGuild
 */

export default function useCurrentGuild() {
  return useContext(UsedGuildContext);
}
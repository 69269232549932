import { useState } from "react";
import { toast } from "react-toastify";
import { getGuildChannels, getGuildConfig } from "../../../../api/get";
import { putGuildConfig } from "../../../../api/put";
import { Loading, UnsavedAlert, useCurrentGuild } from "../../../../components";
import { ScreenContainer, SelectView, TextFieldView } from "../Components";
import useAPI from "../../../../api";
import { useNavigate } from "react-router-dom";
import { FormWrapper } from "../styled";

export default function Tickets() {
  const initialization = {
    categoryId: "",
    state: false,
    channelId: "",
    channelName: "ticket-{number}",
  };
  const { guild } = useCurrentGuild();
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);
  const [form, setForm] = useState(initialization);
  const navigate = useNavigate();

  const { error, loading, setRefresh } = useAPI(
    getGuildChannels,
    [guild?.id],
    (response) => {
      if (response) setData(response);
    }
  );
  const { error: errorConfig, setRefresh: setRefreshConfig } = useAPI(
    getGuildConfig,
    [guild?.id],
    (response) => {
      if (response) setForm(response?.tickets || initialization);
    }
  );

  if (error || errorConfig) navigate("/error");

  const onChange = (e) => {
    setShowAlert(true);
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await putGuildConfig(guild?.id, "tickets", form)
        .then(() => {
          toast.success("Saved!");
        })
        .catch((error) => toast.error(error.message));
      setShowAlert(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const onReset = () => {
    setShowAlert(false);
    setRefreshConfig(Math.random());
  };

  if (loading) return <Loading />;
  else
    return (
      <ScreenContainer
        checked={form.state}
        handleChange={(_, checked) => {
          setShowAlert(true);
          setForm({ ...form, state: checked });
        }}
        button
        onRefresh={() => setRefresh(Math.random())}
      >
        <FormWrapper onSubmit={onSubmit} onReset={onReset}>
          <SelectView
            title="Category"
            name="categoryId"
            onChange={onChange}
            label="Select a category"
            value={form.categoryId}
            description="The category where the ticket channels will be created."
            options={
              data?.length
                ? data
                    ?.filter((x) => x.type === 4)
                    .map((x) => ({ value: x.id, label: x.name }))
                : []
            }
          />
          <SelectView
            title="Ticket Channel"
            name="channelId"
            onChange={onChange}
            label="Select a channel"
            value={form.channelId}
            description="The channel where the ticket channels will be created."
            options={data
              ?.filter((x) => x.type === 0)
              .map((x) => ({ value: x.id, label: `# ${x.name}` }))}
          />
          <TextFieldView
            title="Channel Name"
            name="channelName"
            onChange={onChange}
            label="Type a channel name"
            value={form.channelName}
            description={
              <p>
                The name of the ticket channels. You can use{" "}
                <span style={{ color: "var(--secondary)" }}>{"{user}"}</span>,
                <span style={{ color: "var(--secondary)" }}> {"{id}"}</span>,
                <span style={{ color: "var(--secondary)" }}> {"{number}"}</span>
                ,{"(ex: ticket-{number})"}
              </p>
            }
          />

          <UnsavedAlert show={showAlert} setShow={setShowAlert} />
        </FormWrapper>
      </ScreenContainer>
    );
}

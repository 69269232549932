import { getDatabase, ref, get, onValue } from "firebase/database";
import axios from 'axios';


const endpoint = process.env.REACT_APP_API_ENDPOINT;


function GuildRoles(guildId) {
  return axios.get(`${endpoint}/discord/guilds/${guildId}/roles`, {
    withCredentials: true
  })
}


async function ClientCommands(onChange) {
  try {
    const db = getDatabase();
    const dbRef = ref(db, "commands");
    const response = await get(dbRef);
    onValue(dbRef, onChange)
    return response.val();
  } catch (error) {
    console.error(error)
    return []
  }
}


const GET = {
  GuildRoles,
  ClientCommands
};
export default GET;

import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function LinkButton({ children, to, ...props }) {
  return (
    <Link to={to}>
      <Button {...props}>{children}</Button>
    </Link>
  );
}

import { useContext } from "react";
import UserContext from "../Context/userContext";


/**
 * Current Logged in User
 * @returns {Object} {user, setUser}
 */
export default function useAuth() {
  return useContext(UserContext)
}
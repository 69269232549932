import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  CategoryChild,
  CategoriesContainer,
  CategoryContainer,
  CategoryInfoBox,
  CategoryInfoTitle,
  CategoryInfoSubject,
} from "./styled";

export default function Category({ commands }) {
  const Navigate = useNavigate();
  const [category, setCategory] = useState("all");
  const [list, setList] = useState([]);
  const [query] = useSearchParams();
  const CommandsLength = (category_name) => {
    if (category_name === "all") return commands.length;
    else
      return commands.filter((item) => item.category === category_name).length;
  };
  const icons = {
    economy: "fas fa-money-check-alt",
    fun: "far fa-laugh-squint",
    moderation: "fas fa-cogs",
    utility: "fas fa-wrench",
    setup: "fas fa-clipboard-check",
    giveaway: "fa-solid fa-gift",
    counter: 'fa-solid fa-chart-line'
  };

  useEffect(() => {
    setCategory(query.get("category"));
    const Categories = [];
    Categories.push({
      title: "all",
      className: "category-name",
      length: CommandsLength("all"),
      icon: "fas fa-list",
    });
    for (const command of commands) {
      const ispushed = Categories.find((i) => i.title === command.category);
      if (ispushed) continue;
      Categories.push({
        title: command.category,
        className: "category-name",
        length: CommandsLength(command.category),
        icon: icons[command.category] || 'fa-solid fa-genderless',
      });
    }
    setList(Categories);
  }, [query]);

  return (
    <CategoriesContainer>
      <CategoryContainer>
        {list.map((prop, key) => (
          <CategoryChild
            className={category === prop.title ? "selected-category" : ""}
            onClick={() => Navigate(`?category=${prop.title}`)}
            key={key}
          >
            <i className={prop.icon}></i>
            <p>{prop.title}</p>
            <span>{prop.length}</span>
          </CategoryChild>
        ))}
      </CategoryContainer>
      <CategoryInfoBox>
        <CategoryInfoTitle>Permissions</CategoryInfoTitle>
        <div>
          Hover over the icons to see the required permissions for the specific
          command{" "}
        </div>
        <CategoryInfoSubject>
          <i className="fa-solid fa-robot"></i>{" "}
          <span>Required Permissions For Bot</span>
        </CategoryInfoSubject>
        <CategoryInfoSubject>
          <i className="fa-solid fa-user"></i>{" "}
          <span>Required Permissions For User</span>
        </CategoryInfoSubject>
        <CategoryInfoSubject>
          <i className="fa-solid fa-robot no-permissions"></i>{" "}
          <span>No Bot Permissions Needed</span>
        </CategoryInfoSubject>
        <CategoryInfoSubject>
          <i className="fa-solid fa-user no-permissions"></i>{" "}
          <span>No User Permissions Needed</span>
        </CategoryInfoSubject>
      </CategoryInfoBox>
    </CategoriesContainer>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  position: relative;
  padding: .2rem;
  img  {
      margin-right: .5rem;
      width: 30px;
      border-radius: 50%;
  }
`
export const LoginAvatar = styled.div`
  padding: .3rem .5rem;
  display: flex;
  /* gap: .2rem; */
  border-radius: 1rem;
  align-items: center;
  position: relative;
  background-color: var(--primary);
`

export const DropDownList = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 160%;
  left: -15%;
  text-align: center;
  z-index: 1;
  background-color: var(--primary);
  padding: 1rem .5rem;
  width: 10em;
  border: 1px solid var(--light-gray);
  transition: all .3s;
  border-radius: 5px;
  box-shadow: 2px 2px 15px -10px var(--light-gray);
  &.isOpen {
      visibility: visible;
      opacity: 1;
      top: 135%; 
  }
  a {
      text-decoration: none;
      color: var(--white);
      /* margin: .2rem 0; */
      /* padding: .2rem; */
      :hover {
          background-color: rgba(255, 255, 255, 0.1);
      }
  }
`
export const LogoutButton = styled.div`
  cursor: pointer;
  color: var(--secondary)!important;
  padding: .3rem;
  :hover {
      background-color: rgba(255, 255, 255, 0.1);
  }
`
export const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 .5rem 0 ;
  justify-content: center;
  margin: 0 0 .5rem 0;
  border-bottom: 1px solid var(--gray);
`
import { PayPalButton } from "react-paypal-button-v2";
import React, { useContext } from "react"
import { API_GET } from ".";
import UserContext from "../components/Context/userContext";
import packges from "../routes/Store/package";
import { toast } from "react-toastify";


function Paypal({ value }) {
  const { user } = useContext(UserContext);

  return (
    <PayPalButton
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              currency_code: "USD",
              value: value
            }
          }],
          application_context: {
            shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
          }
        });
      }}

      onApprove={(data, actions) => {
        // Capture the funds from the transaction
        return actions.order.capture().then(function (details) {
          // Show a success message to your buyer
          console.log(details)
          const { created_time, id, payer, purchase_units } = details
          const Kcoins = packges.find(x => x.price === value);
          const amount = parseInt(Kcoins?.amount + Kcoins?.extra)
          const log = {
            type: "store",
            created_time,
            id,
            payer,
            purchase_units: {
              value: purchase_units[0].amount.value,
              amount
            }
          }
          // call the server to save the transaction
          API_GET.updateProfileKcoin(amount, log, user.id)
          toast.success("Transaction completed, thank you for support")
        });
      }}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
      }}
    />
  )
}


export default Paypal;
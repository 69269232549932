import axios from "axios";
const endpoint = process.env.REACT_APP_API_ENDPOINT;


// PUT request to update guild config => /api/guilds/:id/config/:key
export function putGuildConfig(guildId, key, data) {
  return axios.put(`${endpoint}/api/guilds/${guildId}/config/${key}`, {
    data
  }, {
    withCredentials: true
  })
}
// PUT request to send message to channel => /api/channels/:channelId/messages
export function putChannelMessage(channelId, data) {
  return axios.put(`${endpoint}/api/channels/${channelId}/messages`, {
    data
  }, {
    withCredentials: true
  })
}
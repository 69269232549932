import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
`
export const Wrapper = styled.div`
  display: flex;
  color: var(--white);
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary);
  border-radius: 1rem;
  padding: 1rem;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 1rem;
`
export const Icon = styled.div`
  font-size: 10rem;
`
const { initializeApp } = require("firebase/app");

const firebaseConfig = {
  apiKey: "AIzaSyBXETX7aofoEoaqe2VwjN33c4KGe6Ty4Q4",
  authDomain: "keen-commands.firebaseapp.com",
  databaseURL: "https://keen-commands-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "keen-commands",
  storageBucket: "keen-commands.appspot.com",
  messagingSenderId: "633360484654",
  appId: "1:633360484654:web:4adeeb4f7a27891e4752bf",
  measurementId: "G-30VCV6ECMN"
};

// Initialize Firebase
initializeApp(firebaseConfig);

import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Wrapper } from "./styled";

function PageNotFound() {
  let history = useNavigate();
  return (
    <Container>
      <Wrapper>
        <h2>404</h2>
        <p>Sorry, Page not Found</p>
        <Button variant="text" color="secondary" onClick={() => history(-1)}>
          Back
        </Button>
      </Wrapper>
    </Container>
  );
}

export default PageNotFound;

import { Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCurrentUser, getUserGuilds } from "../../api/get";
import { hooks, API_GET } from "../../utils";
import { ExternalURL } from "../../constants";
import useAuth from "../Hooks/useAuth";
import useGuilds from "../Hooks/useGuilds";
import {
  DropDownList,
  Container,
  LoginAvatar,
  LogoutButton,
  UserAvatar,
} from "./styled";

export default function LoginButton() {
  const { user, setUser } = useAuth();
  const { setGuilds } = useGuilds();
  const [clicked, setClicked] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const iconURL = hooks.UserAvatarGenerator(user);
  const history = useNavigate();
  const location = useLocation();
  const listRef = useRef();

  useEffect(() => {
    if (window.location.hostname !== "localhost") setIsTest(true);
    else setIsTest(false);
  }, [location]);

  const handleLogout = () => {
    API_GET.discordLogout();
    setLogin(false);
    history("/");
  };

  useEffect(() => {
    if (user) setLogin(true);
    const handler = (event) => {
      if (!listRef.current?.contains(event.target)) {
        setClicked(false);
      }
    };
    window.addEventListener("mousedown", handler);
    return () => {
      window.addEventListener("mousedown", handler);
    };
  }, [user]);

  const onLogin = () => {
    console.log(ExternalURL.login);
    const popup = window.open(
      ExternalURL.login,
      "_blank",
      "width=500,height=600"
    );
    if (popup) {
      const timer = setInterval(() => {
        if (popup.closed) {
          (async () => {
            const { data } = await getCurrentUser();
            if (data) {
              const { data: guilds } = await getUserGuilds();
              setUser(data);
              setGuilds(guilds);
              history("/dashboard");
            }
            setLogin(true);
            if (timer) clearInterval(timer);
          })();
        }
      }, 500);
    }
  };

  return isLogin ? (
    <Container>
      <LoginAvatar ref={listRef} onClick={() => setClicked(!clicked)}>
        <img src={iconURL} alt={user?.details.username} />
        <span>{user?.details.username} </span>
      </LoginAvatar>
      <DropDownList className={clicked ? "isOpen" : null}>
        <UserAvatar>
          <img src={iconURL} alt={user?.details.username} />
          <span>
            {user?.details.username}#{user?.details.discriminator}
          </span>
        </UserAvatar>
        <Link to="/profile">
          <Button variant="text" fullWidth size="small" color="white">
            Profile
          </Button>
        </Link>
        {user.id === process.env.REACT_APP_API_ADMIN_ID ? (
          <Link to="/admin">
            <Button variant="text" fullWidth size="small" color="white">
              Admin
            </Button>
          </Link>
        ) : null}
        <Link to="/dashboard">
          <Button variant="text" fullWidth size="small" color="white">
            My Servers
          </Button>
        </Link>
        <Button
          variant="text"
          fullWidth
          size="small"
          color="error"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </DropDownList>
    </Container>
  ) : (
    <Button
      disabled={isTest}
      size="small"
      variant="contained"
      color="secondary"
      onClick={onLogin}
    >
      Login
    </Button>
  );
}

import styled from "styled-components";

export const TutorialsContainer = styled.div`
  width: 100%;
  position: relative;
`
export const TutorialsWarpper = styled.div`
  display: flex;
  gap: 5rem;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  margin-top: 5rem;
`
export const CardContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  height: 50vh;
  width: 70%;
  justify-content: space-between;
  :nth-child(even) {
    .warpper {
      flex-direction: row-reverse;
    }
  }
  `
export const CardWarpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CardHeader = styled.div`
  width: 70%;
  position: relative;
  p {
    width: 70%;
  }
  a {
    background-color: var(--secondary);
    color: var(--white);
    text-decoration: none;
    padding: .5rem;
    margin-top: 1rem;
  }
`
export const CardBody = styled.div`
  img {
   width : 80%;
  }
`
export const CardImage = styled.div``

// export const TutorialsContainer = styled.div``
// export const TutorialsContainer = styled.div``
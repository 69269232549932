import { Link } from "react-router-dom";
import styled from "styled-components";

export const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  position: relative;
`
export const Category = styled(Link)`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 1rem;
  align-items: center;
  color: var(--white);
  background-color: var(--primary-light);
  padding: 2rem;
  transition: all 400ms linear;
  text-decoration: none;
  flex: 0 0 27.5%;
  text-align: center;
  position: relative;
  border: 1px solid var(--gray);
  i {
    transition: all 400ms linear;
    color: var(--gray);
  }

  ::before {
    content: "";
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.11)
  }

  :hover {
    border: 1px solid var(--gold);
    box-shadow: inset 0 0 100px -70px var(--gold);

    i {
      color: var(--gold);
    }
  }

`

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  p {
    font-weight: bold;
    font-size: 1.5rem;
  }
`
export const Icon = styled.div`
    margin-bottom: .5rem;
    width: 50px;
    height: 50px;
    padding: .5rem;
    border-radius: 50%;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 3px solid var(--primary);
`

export const Body = styled.div`
  opacity: .6;
`

import React from 'react'
import "./Loading.css"
function Loading() {
    return (
        <div className="calc loading-warp">
            <span className="loading"></span>
        </div>
    )
}

export default Loading

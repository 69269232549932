import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  position: fixed;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%)!important;
  z-index: 100;
  color: var(--white);
  background-color: var(--primary-dark);
  padding: .5rem;
  border-radius: .5rem;
  display: ${(props) => (props.show ? "flex" : "none")};
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`
export const Actions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`
import { useState } from "react";
import { toast } from "react-toastify";
import { getGuildChannels, getGuildConfig } from "../../../../api/get";
import { putGuildConfig } from "../../../../api/put";
import { Loading, UnsavedAlert, useCurrentGuild } from "../../../../components";
import {
  ScreenContainer,
  SelectView,
  SliderView,
  TextFieldView,
} from "../Components";
import useAPI from "../../../../api";
import { useNavigate } from "react-router-dom";
import { FormWrapper } from "../styled";

export default function TemporaryChannels() {
  const initialization = {
    categoryId: "",
    state: false,
    channelId: "",
    channelName: "{user}",
    channelLimit: 0,
    channelTimeout: 10,
  };
  const { guild } = useCurrentGuild();
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState([]);
  const [form, setForm] = useState(initialization);
  const navigate = useNavigate();

  const { error, loading, setRefresh } = useAPI(
    getGuildChannels,
    [guild?.id],
    (response) => {
      if (response) setData(response);
    }
  );
  const { error: errorConfig, setRefresh: setRefreshConfig } = useAPI(
    getGuildConfig,
    [guild?.id],
    (response) => {
      if (response) setForm(response?.temporaryChannels);
    }
  );

  if (error || errorConfig) navigate("/error");

  const onChange = (e) => {
    setShowAlert(true);
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await putGuildConfig(guild?.id, "temporaryChannels", form)
        .then(() => {
          toast.success("Saved!");
        })
        .catch((error) => toast.error(error.message));
      setShowAlert(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const onReset = () => {
    setShowAlert(false);
    setRefreshConfig(Math.random());
  };

  if (loading) return <Loading />;
  else
    return (
      <ScreenContainer
        checked={form.state}
        handleChange={(_, checked) => {
          setShowAlert(true);
          setForm({ ...form, state: checked });
        }}
        button
        onRefresh={() => setRefresh(Math.random())}
      >
        <FormWrapper onSubmit={onSubmit} onReset={onReset}>
          <SelectView
            title="Category"
            name="categoryId"
            onChange={onChange}
            label="Select a category"
            value={form.categoryId}
            description="Select a category where to create the temporary channel"
            options={
              data?.length
                ? data
                    ?.filter((x) => x.type === 4)
                    .map((x) => ({ value: x.id, label: x.name }))
                : []
            }
          />
          <SelectView
            title="Create Channel"
            name="channelId"
            onChange={onChange}
            label="Select a create channel"
            value={form.channelId}
            description="This channel will be used to create a temporary channel when a user sends a join it"
            options={data
              ?.filter((x) => x.type === 2)
              .map((x) => ({ value: x.id, label: x.name }))}
          />
          <TextFieldView
            title="Channel Name"
            name="channelName"
            onChange={onChange}
            label="Type a channel name"
            value={form.channelName}
            description="This will be the template for the channel name. You can use {user} to get the user's name"
          />
          <SliderView
            title="Users Limit"
            name="channelLimit"
            onChange={onChange}
            label="Users Limit"
            value={form.channelLimit}
            description="The maximum number of users that can join the temporary channel"
            marks={[
              { value: 0, label: "∞" },
              { value: 99, label: "99" },
            ]}
            color="info"
            max={99}
            min={0}
            getAriaValueText={(value) => `${value} user`}
          />
          <SliderView
            title="Delete Timeout"
            name="channelTimeout"
            onChange={onChange}
            label="Users Limit"
            value={form.channelTimeout}
            description="The time in seconds after which the temporary channel will be deleted if empty"
            marks={[
              { value: 5, label: "5s" },
              { value: 30, label: "30s" },
            ]}
            color="info"
            max={30}
            min={5}
            getAriaValueText={(value) => `${value} seconds`}
          />
          <UnsavedAlert show={showAlert} setShow={setShowAlert} />
        </FormWrapper>
      </ScreenContainer>
    );
}

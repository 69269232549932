import styled from "styled-components";

export const ScreenSection = styled.section`
  position: relative;
`
export const ScreenLabel = styled.div`
  color: var(--white);
  span {
    color: var(--gray);
  }
`
export const FormGroup = styled.form`
  margin: 2rem 1.5rem;
`
export const InputsGroup = styled.div`
  margin: 1rem 0;
  background-color: var(--primary-light);
  padding: 1rem;
  border-radius: 1rem;
`

export const FormLabel = styled.div`
  margin: 1rem 0;
  padding: 0 0 .5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  color: var(--white);
  position: relative;
  span {
    color: var(--gray);
  }
  p {
    margin: 0.5rem 1rem;
    opacity: .7;
    i {
      background-color: var(--primary);
      padding: .3rem;
    }
  }
`

export const MultiFromInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

`
export const FormInput = styled.div`
  position: relative;
  margin: 1rem 0 0 0;
  width: 45%;
  h4 {
    color: var(--gray);
    text-transform: capitalize;
    margin-bottom: .5rem;
  }
  .select-container {
    .select__control {
      outline: none;
      padding: .2rem;
      color: var(--white);
      font-size: 14px;
      background: var(--primary);
      border: none;
      border-radius: .5rem;
      &.select__control--is-focused {
        box-shadow: none;
        border-color: var(--blue)!important;
      }
      :focus ,:hover {
        border-color: var(--blue);
      }
      .select__value-container {
        .select__single-value {
          color: var(--white)
        }
      }
    }
    .select__multi-value {
      background-color: var(--gray);
      .select__multi-value__label {
        color: var(--white);
      }
    }
    .select__input {
      color: var(--white);
    }
    .select__menu {
      box-shadow: 2px 2px var(--blue);
      color: var(--white);
      background-color: var(--primary);

      * {
      background-color: var(--primary);
      }
      .select__menu-list {
        max-height: 200px;
        overflow-y: auto;
      }
      .select__option {
        opacity: .7;
        :hover, :focus, :active {
          background-color: var(--primary-light);
        }
      }
    }
  }


  input, textarea {
    width: 100%;
    outline: none;
    padding: .7rem;
    color: var(--white);
    font-size: 14px;
    border: none;
    background: var(--primary);
    border-radius: .5rem;
    :focus , :hover {
      border-color: var(--blue);
    }
  }
  textarea {
    resize: none;
  }
`
export const FormExamples = styled.div`
  display: flex;
  div {
    background-color: var(--primary);
    color: var(--white);
    margin: .5rem .5rem;
    padding: .5rem;
    border-radius: .5rem;
  }
`

export const TextAreaLength = styled.div`
  font-size: .8rem;
  color: var(--gray);
  position: absolute;
  bottom: 7%;
  right: 2%;
`




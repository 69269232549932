import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import device from '../../utils/media'

const Bounce = keyframes`
  0%, 100% {
      transform: translateY(0px);
  }
  50% {
      transform: translateY(20px);
  }
`

export const Container = styled.div``

export const HomeLanding = styled.div`
  height: calc(100vh - 80px);
  position: relative;
  img {
    width: 30rem;
    animation: ${Bounce} 3s linear infinite;
  }
  background-color: var(--primary-light);
 
  @media ${device.tablet} {
    img {
    width: 15rem;
    }
    height: auto;
  }
`
export const LandingRow = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media ${device.tablet} {
    justify-content: normal;
    flex-direction: column;
    text-align: center;
    height: 90vh;
  }
`
export const LandingLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
export const HeadLines = styled.div`
  span {
    color: var(--light-gray);
  }
  p {
    color: var(--white);
    font-weight: bold;
    font-size: 3rem;
  }
  @media ${device.tablet} {
  p {
    font-size: 2rem;
  }
    gap: 1rem;
  }
`

export const LayoutLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  a {
    color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
    padding: 1rem 1.1rem;
    i {
      width: 2rem;
    }

    :first-child {
      background-color: var(--primary);
      color: var(--white);
    }
    :last-child {
      background-color: var(--white);
      color: var(--primary);
    }
    :hover {
      filter: brightness(0.9);
    }
  }
  @media ${device.tablet} {
    font-size: .8rem;
    a {
      padding: .8rem .9rem;
    }
  }
`
const Swimming = keyframes`
  0%, 100% {
    filter: drop-shadow(-10px -5px var(--secondary));
  }
  50% {
    filter: drop-shadow(-30px -5px var(--secondary));
  }
`
export const WavesSVG = styled.svg`
  position: absolute;
  bottom: 0;
  fill: var(--primary);
  filter: drop-shadow(-10px -5px var(--secondary));
  animation: ${Swimming} 3s linear infinite;
`

export const PreviewsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 4rem;
  padding: 3rem;
  justify-items: center;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`
export const PreviewsCard = styled(motion.div)`
  height: 40vh;
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  background-color: var(--primary-light);
  text-transform: capitalize;
  padding: 4rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: center;
  transition: 500ms;
  border: 1px solid transparent;
  color: var(--light-gray);

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    clip-path: polygon(0 0, 50% 50%, 100% 100%, 0 100%);
    transition: 0.5s;
  }
 
  i {
    color: var(--secondary);
    font-size: 2rem;
  }
  h2 {
    font-size: 2rem;
  }
  :hover {
    border: 1px solid var(--secondary);
    filter: brightness(1.1);
  }

  @media ${device.tablet} {
    width: 100%;
    font-size: .8rem;
    i {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
`
import React from "react";
import { Logo } from "../../assets";
import Previews from "./previews.json";
import {
  HeadLines,
  Container,
  HomeLanding,
  LandingLayout,
  LandingRow,
  LayoutLinks,
  PreviewsCard,
  PreviewsContainer,
  WavesSVG,
} from "./styled";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <Container>
      <HomeLanding>
        <LandingRow>
          <img alt="" src={Logo}></img>
          <LandingLayout>
            <HeadLines>
              <p>Power Up Your Discord</p>
              <span>
                everything you need to manage your discord server in one place
              </span>
            </HeadLines>
            <LayoutLinks>
              <Link to="/invite">
                <i className="fab fa-discord"></i>Add to discord
              </Link>
              <Link to="/community">
                <i className="fas fa-info-circle"></i>Support
              </Link>
            </LayoutLinks>
          </LandingLayout>
        </LandingRow>
        <WavesSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fillOpacity="1"
            d="M0,32L24,42.7C48,53,96,75,144,112C192,149,240,203,288,218.7C336,235,384,213,432,176C480,139,528,85,576,80C624,75,672,117,720,138.7C768,160,816,160,864,149.3C912,139,960,117,1008,122.7C1056,128,1104,160,1152,165.3C1200,171,1248,149,1296,154.7C1344,160,1392,192,1416,208L1440,224L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"
          ></path>
        </WavesSVG>
      </HomeLanding>
      <PreviewsContainer>
        {Previews.map((prop, key) => (
          <PreviewsCard
            transition={{ duration: 0.5 }}
            whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
            key={key}
            initial={{ opacity: 0 }}
          >
            <i className={prop.icon}></i>
            <h2>{prop.name}</h2>
            <p>{prop.description}</p>
          </PreviewsCard>
        ))}
      </PreviewsContainer>
    </Container>
  );
}

import { motion } from "framer-motion";
import styled from "styled-components";
import device from "../../utils/media";

export const CommandsContainer = styled.div`
  min-height: 100vh;
  width: 100vw;
  padding: 3rem;
  @media ${device.tablet} {
    padding: 1rem;
  }
`
export const CommandsView = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
  margin-top: 3rem;
  div {
    width: 100%;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  } 
`
export const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const CommandsInfoBox = styled.div`
  background-color: var(--primary-light);
  padding: 1rem;
  border-radius: 1rem;
  color: var(--white);
`
export const CommandsInfoBoxTitle = styled.h2`
  color: var(--gold);
`
export const CommandsInfoBoxSubject = styled.p`

`
// #################### end of index ##############################

// CategoryContainer
export const CategoriesContainer = styled.div`
  background-color: var(--primary-light);
  padding: 1rem;
  border-radius: 1rem;
  height: fit-content;
  @media ${device.tablet} {
    height: auto;
  } 
`
export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const CategoryChild = styled.div`
  width: 100%;
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  color: var(--white);
  font-weight: bold;
  padding: .5rem 1rem;
  border-radius: 1rem;
  transition: 500ms;
  cursor: pointer;
  text-transform: uppercase;
  :hover {
    background-color: var(--primary);
  }
  &.selected-category {
    background-color: var(--primary);
  }
  @media ${device.tablet} {
    font-size: .9rem;
  } 
`
export const CategoryInfoBox = styled.div`
  color: var(--white);
  padding: .5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  background-color: var(--primary);
  border-radius: .4rem;
`
export const CategoryInfoTitle = styled.h3`
  
`
export const CategoryInfoSubject = styled.div`
  font-size: .9rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  /* background-color: var(--primary); */
  /* padding: .5rem;1 */
  /* border-radius: .3rem; */
  i {
    &.no-permissions {
      color: var(--gray);
      background-color: transparent;
    }
    width: 30px;
    height: 30px;
    background-color: var(--dark-gold);
    color: var(--gold);
    display: grid;
    place-items: center;
    border-radius: 50%
  }
`

// #################### end of Category ##############################

export const CommandContainer = styled.div`
  color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const CommandRow = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: ${(props) => props.isNewCommand ? "var(--dark-gold)" : "var(--primary-light)"} ;
  border-radius: 1rem;
  cursor: pointer;
  transition: 500ms;
  border: 1px solid rgba(255, 255, 255, 0);
  :hover {
    border: 1px solid var(--white);
  }
`

export const CommandHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 1rem;
  p {
    font-size: .9rem;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 1rem;
  }

  @media ${device.tablet} {
    font-size: .7rem;
    p {
      font-size: .7rem;
    }
  } 
`
export const CommandTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`



export const CommandPermissions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`
export const CommandPermissionsTooltip = styled.i`
  position: relative;
  &.no-permissions {
    color: var(--gray);
    background-color: transparent;
  cursor: not-allowed;
  }
  background-color: var(--dark-gold);
  color: var(--gold);
  width: 30px;
  display: grid;
  place-items: center;
  height: 30px;
  border-radius: 50%;
  /* &[title]:hover::after  {
    content: attr(title);
    position: absolute;
    top: -200%;
    font-size: .7rem;
    background-color: var(--white);
    padding: .4rem;
    color: var(--primary);
    display: flex;

  } */
`

export const CommandBody = styled.div`
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: 500ms;
  &.selected-command {
    height: ${(props) => props.length * 3 + 1.5}rem;
    padding: 1rem;
  }
`
export const CommandOptionTitle = styled.div`

`
export const CommandOptions = styled.div`
  display: flex;
  flex-direction: column;
  div {
    color: var(--light-gray);
    display: flex;
    align-items: center;
    font-size: .8rem;
    h3{ 
      margin: .3rem 0;
      span{
        margin: 0 .5rem 0 0;
        font-weight:bold ;
        background-color: var(--dark-gold);
        color: var(--gold);
        font-size: .8rem;
        padding: .2rem;
        /* margin: 0 1rem; */
        border-radius: .3rem;
        text-transform: uppercase;
      }
    }
    p {
      margin: 0 .5rem;
    }
  }
  @media ${device.tablet} {
    div {
      font-size: .7rem;
      span {
        font-size: .7rem;
      }
    }
  } 
`
export const TooltipsContainer = styled.div`
  color: var(--white);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: 100%;
  left: -100%;
  font-size: .7em;
  position: absolute;
  z-index: 1;
  background-color: var(--primary);
  width: 100%;
  white-space: nowrap;
`
export const SearchContainer = styled.div`
  width: 100%;
  position: relative;  
  display: flex;
  align-items: center;
  background-color: var(--primary-light);
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid var(--white);
  input {
    background-color: var(--primary-light);
    border: none;
    outline: none;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
    color: var(--light-gray);
  }
  i {
    margin: 0 0 0 1rem;
  }
`

export const NotFound = styled.div`
  background-color: var(--dark-gold);
  color: var(--gold);
  padding: 1rem;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 1rem;
`
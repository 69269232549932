import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT;


function updateGuildPrefix(prefix, guildId) {
    return axios.put(`${endpoint}/discord/guilds/${guildId}/prefix`, {
        prefix
    }, {
        withCredentials: true
    })
}

function updateGuildAutoRole(guildId, roles) {
    return axios.put(`${endpoint}/discord/guilds/${guildId}/roles/joinroles`, {
        roles
    }, {
        withCredentials: true
    })
}
function updateUserProfile(id, profile) {
    return axios.put(`${endpoint}/discord/users/profile/${id}/update`, {
        profile
    }, {
        withCredentials: true
    })
}


function UpdateGuildLogs(guildId, Logs) {
    return axios.put(`${endpoint}/discord/guilds/${guildId}/logs`, {
        Logs
    }, {
        withCredentials: true
    })
}
function updateBackgrounds(bgs) {
    return axios.put(`${endpoint}/assets/background/update`, {
        bgs
    }, {
        withCredentials: true
    })
}
function updateProfileBgs(profile, id) {
    return axios.put(`${endpoint}/discord/users/profile/${id}/bgs`, {
        profile
    }, {
        withCredentials: true
    })
}
function updateProfileKcoin(amount, log, id) {
    return axios.put(`${endpoint}/store/profile/${id}/kcoin`, {
        amount, log
    }, {
        withCredentials: true
    })
}
function updateProfileSubscribe(id, membership) {
    return axios.put(`${endpoint}/store/profile/${id}/subscribe`, {
        membership
    }, {
        withCredentials: true
    })
}
function updateBoostedGuilds(id, guildId) {
    return axios.put(`${endpoint}/store/profile/${id}/guild/${guildId}/boost`, {
        withCredentials: true
    })
}

const api = { updateBoostedGuilds, updateProfileSubscribe, updateProfileKcoin, updateProfileBgs, updateBackgrounds, updateUserProfile, updateGuildAutoRole, updateGuildPrefix, UpdateGuildLogs }


export default api;
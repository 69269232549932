import styled from "styled-components";



export const Container = styled.div`
  min-height: 100vh;
  background-color: var(--primary-light);
  border-top: 2px solid var(--primary);
`
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const Route = styled.div`
  color: var(--light-gray);
  font-size: 1.1rem;
`
export const ContentContainer = styled.div``
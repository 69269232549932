import styled, { keyframes } from "styled-components";


export const animate = keyframes`
  from {
    content: "";
  }
  to {
    content: ".";
  }
`;
export const Container = styled.div`
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
export const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
  gap: 1rem;
  i {
    font-size: 7rem;
    color: var(--secondary);
  }
  h3 {
    color: var(--light-gray);
    font-weight: bold;
    text-shadow: 2px 2px var(--secondary);
    text-transform: uppercase;
    ::after {
      animation: ${animate} 1000ms ease-in-out infinite;
      content: "";
    }
  }
`;

export const Content = styled.div`
  background-color: var(--primary-light);
  font-size: large;
  padding: 1rem;
  border-radius: 0.5rem;
  color: var(--light-gray);
`;

import URL from "./url";

function getCurrentGuild(guilds, guildConfig) {
    if (guilds?.included && guildConfig) {
        return guilds.included.find(g => g.id === guildConfig.Guild);
    }
    return null;
}

function getGuildIcon(guild) {
    var format = "png"
    if (guild?.icon) {
        if (guild.icon.startsWith("a_")) format = "gif"
        return `https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.${format}`;
    }
    else return
}

function getUserAvatar(user) {
    var format = "png"
    if (user?.details.avatar) {
        if (user?.details.avatar.startsWith("a_")) format = "gif"
        return `https://cdn.discordapp.com/avatars/${user?.id}/${user?.details.avatar}.${format}`;
    }
    else return `https://better-default-discord.netlify.app/Icons/Solid-Gray.png`
}

const ClientEvents = [
    'channel_Create',
    'channel_Update',
    'channel_Delete',
    'guild_Ban_Add',
    'guild_Ban_Remove',
    'role_Create',
    'role_Delete',
    'role_Update',
    'guild_Update',
    'message_Delete',
    'message_Delete_Bulk',
    'message_Reaction_Remove_All',
    'message_Update',
    'guild_Member_Add',
    'guild_Member_Kick',
    'guild_Member_Remove',
    'guild_Member_Update',
    'voice_Channel_Leave',
    'voice_Channel_Join',
    'voice_State_Update',
    'voice_Channel_Switch',
    'guild_Emojis_Update',
    'guild_Member_NickUpdate'
]


function AskToLogin() {
    if (window.confirm("You can access to this after login. Do you want to go to the login page now?")) {
        window.location.href = URL.login;
    } else return null;
}


export { AskToLogin, getCurrentGuild, getGuildIcon, ClientEvents, getUserAvatar }
import { Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { deleteItem, getItemsList } from "../../../utils/api/firebase";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardImage,
  CardMedia,
  CardQuote,
  CardWrapper,
  Container,
  Wrapper,
} from "./styled";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ItemsList() {
  const [items, setItems] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await getItemsList((snap) => {
        setItems(snap.val());
      });
      setItems(response);
    })();
  }, []);

  const onDeleteItem = async (id) => {
    try {
      await deleteItem(id)
        .then(() => {
          console.log("Item deleted successfully!");
        })
        .catch(console.error);
    } catch (error) {
      console.error(error);
    }
  };

  console.log(items);

  return (
    <Container>
      <Wrapper>
        <CardWrapper>
          {Object.keys(items).map((item, index) => (
            <Card key={index}>
              <CardHeader>{items[item].name}</CardHeader>
              <CardMedia>
                <CardImage src={items[item]?.icon} alt={items[item].name} />
                <span>Weight: {items[item].weight} LT</span>
              </CardMedia>
              <CardContent>
                <CardQuote>
                  <span>Description: {items[item].description}</span>
                </CardQuote>
                <CardQuote>
                  <span>
                    Buy Price: <i>{items[item].buy_price}$</i>
                  </span>
                  <span>
                    Sell Price: <i>{items[item].sell_price}$</i>
                  </span>
                  <span>
                    Repair Price: <i>{items[item].repair_price}$</i>
                  </span>
                </CardQuote>
              </CardContent>
              <CardActions>
                <IconButton
                  onClick={() => onDeleteItem(items[item].id)}
                  size="small"
                  color="error"
                  variant="contained"
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </CardWrapper>
      </Wrapper>
    </Container>
  );
}

import { Button } from "@mui/material";
import React from "react";
import { Actions, Container, Wrapper } from "./styled";

export default function UnsavedAlert({ show, setShow }) {
  const onCancel = () => {
    setShow(false);
  };
  return (
    <Container
      show={show}
      initial={{ opacity: 0, y: -100 }}
      whileInView={{ opacity: [0, 0, 1], y: [100, 50, 0] }}
      transition={{
        duration: 0.5,
      }}
    >
      <Wrapper>
        <p>You have unsaved changes!</p>
        <Actions>
          <Button type="submit" size="small" variant="contained" color="info">
            Save
          </Button>
          <Button
            onClick={onCancel}
            type="reset"
            size="small"
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
        </Actions>
      </Wrapper>
    </Container>
  );
}

import { Button, TextField } from "@mui/material";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
import {
  deleteFile,
  setNewItem,
  uploadFile,
} from "../../../utils/api/firebase";
import {
  Container,
  Form,
  Group,
  GroupColumn,
  IconContainer,
  IconPreview,
  Wrapper,
} from "./styled";
export default function Items() {
  const iconRef = useRef(null);
  const [file, setFile] = useState("");
  const [form, setForm] = useState({
    name: "",
    id: uuidV4(),
    icon: "",
    buy_price: "",
    sell_price: "",
    repair_price: "",
    description: "",
    type: "",
    weight: "",
    durability: "",
    consume: "",
    category: "",
    createdAt: "",
  });

  const onChange = ({ target }) => {
    setForm((state) => ({
      ...state,
      [target.name]: target.value,
      createdAt: Date.now(),
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await setNewItem(form)
      .then(() => {
        toast.success("Item added successfully!");
      })
      .catch(console.error);
  };

  const readImage = () => {
    const file = iconRef.current.files[0];
    if (!file) return toast.error("Please select an image file");
    setFile(file);
  };

  const UploadIcon = async () => {
    await uploadFile(file, `/items/${form.id}.png`, {
      onComplete: (uri) => {
        setForm((state) => ({ ...state, icon: uri }));
        toast.success("Icon uploaded successfully");
      },
    });
  };

  const DeleteIcon = async () => {
    await deleteFile(`/items/${form.id}.png`).then(() => {
      setForm((state) => ({ ...state, icon: "" }));
      toast.success("Icon deleted successfully");
    }).catch(console.error);
  };

  return (
    <Container>
      <Wrapper>
        <h2>Add New Item</h2>
        <Form onSubmit={onSubmit}>
          <Group>
            <IconPreview src={form.icon} alt="" />
            <GroupColumn>
              <TextField
                fullWidth
                required
                label="Name"
                color="secondary"
                variant="filled"
                name="name"
                onChange={onChange}
                value={form.name}
              />
              <TextField
                fullWidth
                label="ID"
                color="secondary"
                variant="filled"
                name="id"
                onChange={onChange}
                value={form.id}
              />
            </GroupColumn>
          </Group>
          <TextField
            fullWidth
            required
            multiline
            label="Description"
            color="secondary"
            variant="filled"
            name="description"
            onChange={onChange}
            value={form.description}
          />
          <Group>
            <TextField
              fullWidth
              required
              label="Buy Price"
              color="secondary"
              type="number"
              variant="filled"
              name="buy_price"
              onChange={onChange}
              value={form.buy_price}
            />
            <TextField
              fullWidth
              required
              label="Sell Price"
              color="secondary"
              variant="filled"
              type="number"
              name="sell_price"
              onChange={onChange}
              value={form.sell_price}
            />
            <TextField
              fullWidth
              required
              type="number"
              label="Repair Price"
              color="secondary"
              variant="filled"
              name="repair_price"
              onChange={onChange}
              value={form.repair_price}
            />
          </Group>
          <Group>
            <TextField
              fullWidth
              required
              label="Type"
              color="secondary"
              variant="filled"
              name="type"
              onChange={onChange}
              value={form.type}
            />
            <TextField
              fullWidth
              required
              label="Category"
              color="secondary"
              variant="filled"
              name="category"
              onChange={onChange}
              value={form.category}
            />
          </Group>
          <Group>
            <TextField
              fullWidth
              required
              type="number"
              label="Weight"
              color="secondary"
              variant="filled"
              name="weight"
              onChange={onChange}
              value={form.weight}
            />
            <TextField
              fullWidth
              required
              type="number"
              label="Durability"
              color="secondary"
              variant="filled"
              name="durability"
              onChange={onChange}
              value={form.durability}
            />
            <TextField
              fullWidth
              required
              type="number"
              label="Consume"
              color="secondary"
              variant="filled"
              name="consume"
              onChange={onChange}
              value={form.consume}
            />
          </Group>
          <IconContainer>
            <input
              accept="image/*"
              required
              ref={iconRef}
              type="file"
              label="Icon"
              color="secondary"
              variant="filled"
              name="icon"
              onChange={readImage}
            />
            <Button
              disabled={form.icon !== ""}
              onClick={UploadIcon}
              color="info"
              variant="contained"
            >
              Upload
            </Button>
            <Button
              disabled={!form.icon}
              onClick={DeleteIcon}
              color="error"
              variant="contained"
            >
              Delete
            </Button>
          </IconContainer>

          <Button type="submit" color="success" variant="contained">
            Add the item
          </Button>
        </Form>
      </Wrapper>
    </Container>
  );
}

import "./config/global.styles.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Navbar, Footer, Loading, UserContext } from "./components";
import { useState } from "react";
import GuildsContext from "./components/Context/GuildsContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainRoutes from "./routes";
import { getCurrentUser, getUserGuilds } from "./api/get";
import useAPI from "./api";

function App() {
  const [user, setUser] = useState(null);
  const [guilds, setGuilds] = useState({});

  const { loading } = useAPI(getCurrentUser, null, (data) => {
    setUser(data);
  });

  useAPI(getUserGuilds, null, (data) => {
    setGuilds(data);
  });

  if (loading) return <Loading />;
  else
    return (
      <UserContext.Provider value={{ user, setUser }}>
        <GuildsContext.Provider value={{ guilds, setGuilds }}>
          <BrowserRouter>
            <main>
              <Navbar />
              <ToastContainer
                theme="dark"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Routes>
                {MainRoutes?.main.map((prop, key) => {
                  if (prop.disable) return;
                  return (
                    <Route key={key} path={prop.path} element={prop.element} />
                  );
                })}
              </Routes>
              <Footer />
            </main>
          </BrowserRouter>
        </GuildsContext.Provider>
      </UserContext.Provider>
    );
}

export default App;

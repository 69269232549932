/**
 *
 * @param {string} uri window url
 * @param {object} param1 window options
 * @returns window reference
 */

export default function PopupWindow(
  uri,
  options = { width: 500, height: 650 }
) {
  const { width, height } = options;
  return window.open(uri, "_blank", `width=${width},height=${height}`);
}

import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ScreenHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h2 {
    color: var(--white);
    text-transform: capitalize;
  }
  p {
    color: var(--gray-2);
    font-weight: bold;
  }
`
export const ScreenHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

`
export const ActiveButton = styled.div`
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`

// SelectView
export const ComponentContainer = styled.div`
  background-color: var(--primary-light);
  width: 100%;
  padding: 1rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  color: var(--white);
`
export const ComponentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
`

export const ComponentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem 0;
  p {
    color: var(--gray-2);
    font-weight: bold;
  }
`
import styled from "styled-components";
export const UpdateTime = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  span {
    background-color: var(--primary-light);
    width: fit-content;
    padding: .5rem;
    border-radius: 1rem;
  }
`
export const TermsContainer = styled.div`
  width: 70%;
  margin: auto;
  color: rgba(255, 255, 255, .4);
  padding: 2rem;


  h2 {
    text-align: center;
    margin: 2rem;
    color: var(--white);
    font-size: 30px;
    strong {
      color: var(--secondary);
    }
  }
  h3 {
    color: var(--white);
    margin: 2rem 0 .5rem 0;
  }

  a {
    color: var(--blue)
  }
  p {
    margin: 0 1rem;
  }
  ul {
    margin: 0 3rem;
  }
`
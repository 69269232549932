import React, { useContext } from 'react'
import { Amount, Extra, Info, Package, PackageHeader, PackagePrice, PackagesContanier, PurchaseButton, StoreContanier, StoreTitle } from './style'
import Coin from '../../components/Tags/Coin'
import packges from "./package"
import Paypal from '../../utils/paypal'
import UserContext from '../../components/Context/userContext'
import { AskToLogin } from '../../utils/get'
function Store() {
  const { user } = useContext(UserContext);

  const handleClick = () => {
    if (!user) {
      AskToLogin()
    }
  }

  return (
    <StoreContanier>
      <StoreTitle>
        Select the amount of Kcoin you want to purchase.
      </StoreTitle>
      <div id="paypal-button"></div>
      <PackagesContanier >
        {
          packges.map((prop, key) => (
            <Package key={key}>
              <PackageHeader>
                <Amount>{prop.amount} Kcoin</Amount>
                {prop.extra ? <Extra>+ {prop.extra}</Extra> : null}
              </PackageHeader>
              <Coin size="150" />
              <PackagePrice>
                ${prop.price}
              </PackagePrice>
              {
                user ? <Paypal value={prop.price} packages={packges} /> : <PurchaseButton onClick={handleClick}>Purchase</PurchaseButton>
              }
              {/* <PurchaseButton >
                Purchase
              </PurchaseButton> */}
            </Package>
          ))
        }
      </PackagesContanier>
      <Info>
        <li>All Kcoin Recharges/Purchases are final and cannot be refunded/withdrawn </li>
        <li>Please verify you have the correct account and amount before completing your purchase</li>
        <li>Kcoin is used in the order it was purchased.</li>
      </Info>
    </StoreContanier>
  )
}

export default Store

import { NavLink } from "react-router-dom";
import { BackButton, Container, Header, Server } from "./styled";
import { UsedGuildContext } from "../../../components";
import { hooks } from "../../../utils";
import {
  Avatar,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useContext, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import screens from "../screens";

export default function Sidebar() {
  const { guild } = useContext(UsedGuildContext);
  const [open, setOpen] = useState([1]);

  const onToggle = (i) => {
    if (open.includes(i)) {
      setOpen(open.filter((item) => item !== i));
    } else {
      setOpen([...open, i]);
    }
  };

  return (
    <Container>
      <Header>
        <BackButton to="/dashboard">
          <ArrowBackIosIcon />
        </BackButton>
        <Server to={`/dashboard/${guild?.id}`}>
          <span>{guild?.name}</span>
          <Avatar src={hooks.GuildIconGenerator(guild)} alt="" />
        </Server>
      </Header>
      <List>
        {screens
          ?.filter((x) => !x.hidden)
          .map((item, i) => (
            <List key={i}>
              <ListItemButton
                sx={{ backgroundColor: "var(--primary)" }}
                onClick={() => onToggle(i)}
              >
                <ListItemText
                  sx={{ color: "var(--light-gray)" }}
                  primary={item.title}
                />
                {open ? (
                  <ExpandLess sx={{ color: "var(--gray)" }} />
                ) : (
                  <ExpandMore sx={{ color: "var(--gray)" }} />
                )}
              </ListItemButton>
              <Collapse in={open.includes(i)} timeout="auto" unmountOnExit>
                {item?.routes?.map((prop, index) => (
                  <NavLink key={index} to={`${prop.path}`}>
                    <ListItemButton color="white">
                      <ListItemIcon sx={{ color: "var(--light-gray)" }}>
                        {prop.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "var(--light-gray)" }}
                        primary={prop.name}
                      />
                    </ListItemButton>
                  </NavLink>
                ))}
              </Collapse>
            </List>
          ))}
      </List>
    </Container>
  );
}

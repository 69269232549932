import React from "react";
import { useNavigate } from "react-router-dom";
import { DevelopmentContainer, GoBackButton } from "./styled";

function UnderDevelopment() {
  let history = useNavigate();
  return (
    <DevelopmentContainer>
      <h2>Under Development</h2>
      <p>Sorry, This page still under development.</p>
      <GoBackButton onClick={() => history(-1)}>Back</GoBackButton>
    </DevelopmentContainer>
  );
}

export default UnderDevelopment;

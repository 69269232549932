import styled from "styled-components";

export const InputContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
`
export const Group = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`
export const ColorPicker = styled.input`
  -webkit-appearance: none;
  border: 2px solid var(--gray);
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::-webkit-color-swatch {
	  border: none;
  }
`
ColorPicker.defaultProps = {
  type: "color"
}
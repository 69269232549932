import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: linear-gradient(var(--primary-light), var(--primary));
  min-height: 100%;
  font-size: .9rem;
  padding: 2rem 1rem;
  overflow-x: hidden;
  border-top: 2px solid var(--primary);
  border-top-right-radius: 1rem;
  /* 
  @media (max-width: 900px) {
    width: 50px;
  } */
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

`
export const BackButton = styled(Link)`
  padding: .5rem 1rem;
  border-radius: .5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  height: 100%;
  color: var(--white);
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, .3);
  }
`
export const Server = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: var(--white);
  font-size: 1.2rem;
  font-weight: 600;
  justify-content: space-between;
  background-color: var(--primary);
  padding: .5rem 1rem;
  border-radius: .5rem;
  :hover {
    background-color: rgba(0, 0, 0, .3);
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  /* span {
    margin: 10px 0;
    color: var(--gray);
    font-size: .8rem;
  } */

  /* a {
    text-decoration: none;
    letter-spacing: 1px;
    color: var(--light-gray);
    list-style: none;
    cursor: pointer;
    padding: 8px 12px;
    transition: background .5s;
    border-radius: .8rem;
    transition: padding .3s linear;
    i {
      width: 1.5rem;
    }

    &.active {
      background-color: var(--primary);
      padding-left: 18px;
    }
    :hover {
      padding-left: 18px;
    }
  } */
`
export const CardTitle = styled.div`
  margin: .5rem 0;
  color: var(--white);
  font-size: .7rem;
  font-weight: bold;
  letter-spacing: 1px;
`
import React from "react";
import { SearchContainer } from "./styled";

export default function Searching({ search, setter }) {
  const onChange = (e) => {
    setter(e.target.value);
  };

  return (
    <SearchContainer>
      <i className="fa-solid fa-magnifying-glass"></i>
      <input
        onChange={onChange}
        value={search}
        type="search"
        placeholder="Search for a command..."
      />
    </SearchContainer>
  );
}

import TemporaryChannels from "./TemporaryChannels"
import Overview from './Overview'
import AddBoxIcon from '@mui/icons-material/AddBox';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Welcome from "./Welcome";
import SendIcon from '@mui/icons-material/Send';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Embed from "./Embed";
import Tickets from "./Tickets";
const screens = [
  {
    category: "hidden",
    hidden: true,
    routes: [
      {
        name: "Temporary Channels",
        icon: <AddBoxIcon />,
        path: "/",
        description: "Create temporary channels for your server!",
        element: <Overview />

      }
    ]
  },

  {
    title: "Server Management",
    routes: [
      {
        name: "Tickets",
        icon: <ConfirmationNumberIcon />,
        path: `tickets`,
        description: "Setup a ticket system for your server!",
        element: <Tickets />
      },
      {
        name: "Welcome",
        icon: <EmojiPeopleIcon />,
        path: `welcome`,
        description: "Always say hello to your new members!",
        element: <Welcome />
      },
      {
        name: "Temporary Channels",
        icon: <AddBoxIcon />,
        path: `temp-channels`,
        description: "Create temporary channels for your server!",
        element: <TemporaryChannels />
      },
    ],
  },
  {
    title: "Utilities",
    routes: [
      {
        name: "Embed Messages",
        icon: <SendIcon />,
        path: `warnings`,
        description: "Send embed messages to your server through the bot!",
        element: <Embed />
      }
    ]
  },
]

export default screens;
import React from "react";
import styled from "styled-components";

export default function NewCommand() {
  return (
    <Container>
      <i className="fa-solid fa-star"></i> New!
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  color: var(--gold);
  font-size: 0.8rem;
`;

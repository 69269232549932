const Routes = [
  {
    header: "Keenbot",
    routes: [
      {
        name: "Support server",
        url: "/community"
      },
      {
        name: "Premium",
        url: "/premium"
      },
      {
        name: "Build your bot",
        url: "/custom"
      }
    ]
  },
  {
    header: "Company",
    routes: [
      {
        name: "Terms of use",
        url: "/terms"
      },
      {
        name: "Privacy Policy",
        url: "/privacy-policy"
      },
      {
        name: "Report a bug",
        url: "/report"
      },
      {
        name: "Suggest",
        url: "/suggest"
      }
    ]
  }
]

export default Routes;
import axios from "axios";
const endpoint = process.env.REACT_APP_API_ENDPOINT;



export function getCurrentUser() {
  return axios.get(`${endpoint}/api/user`, {
    withCredentials: true
  })
}

export function getUserGuilds() {
  return axios.get(`${endpoint}/api/guilds`, {
    withCredentials: true
  })
}

export function getGuildChannels(guildId) {
  return axios.get(`${endpoint}/api/guilds/${guildId}/channels`, {
    withCredentials: true
  })
}

export function getGuildConfig(guildId) {
  return axios.get(`${endpoint}/api/guilds/${guildId}/config`, {
    withCredentials: true
  })
}
export function getGuildRoles(guildId) {
  return axios.get(`${endpoint}/api/guilds/${guildId}/roles`, {
    withCredentials: true
  })
}

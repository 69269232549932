import { lazy } from 'react'
import Admin from './Admin';
import Home from './Home';
import UnderDevelopment from './UnderDev';
import PageNotFound from './404';
import Tutorials from './Tutorials';
import Store from './Store/index';
import Wheel from './Wheel/Wheel';
import Terms from './Terms'
import Privacy from './Privacy'
// import Premium from './Premium'
// import Profile from './Profile';
import Report from './Feedback/Report'
import Suggest from './Feedback/Suggest/Suggest'
import Dashboard from './Dashboard'
import Servers from './Dashboard/servers'
import Commands from './Commands'
import Redirect from '../components/Redirect';
import { ExternalURL } from '../constants';
import LoginRedirect from './LoginRedirect';
import Error from './Error';
import Protected from '../components/Protected';

export default {
  dashboard: [
    {
      name: "*",
      path: "*",
      element: <PageNotFound />,
    },
  ],
  main: [
    {
      name: "Error",
      path: "/error",
      element: <Error />,
    },
    {
      name: "*",
      path: "*",
      element: <PageNotFound />,
    },
    {
      name: "Home",
      path: "/",
      element: <Home />,
    },
    {
      name: "Admin",
      path: "/admin/*",
      element: <Admin />,
    },
    {
      name: "Login Redirect",
      path: "/login/redirect",
      element: <LoginRedirect />,
    },
    {
      name: "Tutorials",
      path: "/tutorials",
      element: <Tutorials />,
      disable: true,
    },
    {
      name: "404 Error",
      path: "/404",
      element: <PageNotFound />,
    },
    {
      name: "Store",
      path: "/store",
      element: <Store />,
      disable: true,
    },
    {
      name: "Wheel",
      path: "/wheel",
      element: <Wheel />,
      disable: true,
    },
    {
      name: "Terms",
      path: "/terms",
      element: <Terms />,
    },
    {
      name: "Privacy Policy",
      path: "/privacy-policy",
      element: <Privacy />,
    },
    {
      name: "Premium",
      path: "/premium",
      element: <UnderDevelopment />,
    },
    {
      name: "Suggest",
      path: "/suggest",
      element: <Suggest />,
      disable: true,
    },
    {
      name: "Report",
      path: "/report",
      element: <Report />,
    },
    {
      name: "Marketplace",
      path: "/market",
      element: <UnderDevelopment />,
      disable: true,
    },
    {
      name: "Dashboard",
      path: "/dashboard/:id/*",
      element: <Protected requiredUser><Dashboard /></Protected>,
    },
    {
      name: "Servers List",
      path: "/dashboard",
      element: <Protected requiredUser><Servers /></Protected>,
    },
    {
      name: "Commands",
      path: "/commands",
      element: <Commands />,
    },
    {
      name: "Community",
      path: "/community",
      element: <Redirect uri={ExternalURL.support_server} />,
    },
    {
      name: "Profile",
      path: "/profile",
      element: <UnderDevelopment />,
      disable: true
    },
    {
      name: "Invite Bot",
      path: "/invite",
      element: <Redirect uri={ExternalURL.invite} />,
    }
  ]
}
import { createRoot } from 'react-dom/client';
import App from './App'
import { ThemeProvider } from "@mui/material/styles";
import React from 'react';
import theme from './config/theme'
import "./config/firebaseAuth"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


const container = document.getElementById('root');
const root = createRoot(container);

Sentry.init({
  dsn: "https://fe907de0ab6f4250984887fda197a3e0@o4504061197287424.ingest.sentry.io/4504061198860288",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.7,
});


root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App tab="home" />
    </ThemeProvider>
  </React.StrictMode>
);
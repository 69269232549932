import React, { useEffect, useState } from "react";
import {
  CommandBody,
  CommandContainer,
  CommandHeader,
  CommandOptions,
  CommandOptionTitle,
  CommandPermissions,
  CommandPermissionsTooltip,
  CommandRow,
  CommandTitle,
  NotFound,
} from "./styled";
import { useSearchParams } from "react-router-dom";
import Searching from "./Searching";
import { NewCommand } from "../../components";

export default function Command({ commands }) {
  const [selected, setSelected] = useState(false);
  const [category, setCategory] = useState("all");
  const [searching, setSearching] = useState([]);
  const [commandsList, setCommandsList] = useState([]);

  const [query] = useSearchParams();

  useEffect(() => {
    setCategory(query.get("category"));
  }, [query]);

  useEffect(() => {
    const filter = commands.filter((c) => {
      if (category === "all") return c;
      else return c.category === category;
    });
    setCommandsList(filter);
  }, [category, commands]);

  useEffect(() => {
    const filter = commands.filter(
      (c) => c.name.includes(searching) || c.description.includes(searching)
    );
    setCommandsList(filter);
  }, [searching, commands]);

  const handleCommandClick = (prop) => {
    setSelected(prop.name);
    // Navigate(`?category=${query.get("category")}&command=${prop.name}`);
  };

  const isNewCommand = (time) => {
    const birthtime = new Date(time).getTime();
    const now = new Date().getTime();
    const diff = now - birthtime;
    const days = diff / (1000 * 60 * 60 * 24);
    return days < 14;
  };

  if (!commandsList.length)
    return (
      <CommandContainer>
        <Searching search={searching} setter={setSearching} />
        <NotFound>
          <i className="fa-solid fa-circle-exclamation"></i> Nothing was found
          matching your search.
        </NotFound>
      </CommandContainer>
    );
  else
    return (
      <CommandContainer>
        <Searching search={searching} setter={setSearching} />
        {commandsList.map((prop, index) => (
          <CommandRow
            isNewCommand={isNewCommand(prop.birthtime)}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            whileInView={{ opacity: [0, 0, 1], y: [100, 50, 0] }}
            key={index}
            onClick={() => handleCommandClick(prop)}
          >
            <CommandHeader>
              <div>
                <CommandTitle>
                  <h2>
                    / {prop?.subcommand?.child} {prop.name}
                  </h2>
                  <span>
                    {isNewCommand(prop.birthtime) ? <NewCommand /> : ""}
                  </span>
                </CommandTitle>
                <p>┕ {prop.description}</p>
              </div>
              <CommandPermissions color={prop?.permissions}>
                <CommandPermissionsTooltip
                  title={
                    prop?.permissions?.user &&
                    prop?.permissions?.user
                      .map((p) => p.split("_").join(" "))
                      .join(", ")
                  }
                  className={
                    prop?.permissions?.user
                      ? "fa-solid fa-user"
                      : "fa-solid fa-user no-permissions"
                  }
                />
                <CommandPermissionsTooltip
                  title={
                    prop?.permissions?.client &&
                    prop?.permissions?.client
                      .map((p) => p.split("_").join(" "))
                      .join(", ")
                  }
                  className={
                    prop?.permissions?.client
                      ? "fa-solid fa-robot"
                      : "fa-solid fa-robot no-permissions"
                  }
                />
              </CommandPermissions>
            </CommandHeader>
            <CommandBody
              length={prop?.options?.length || 0}
              className={
                selected === prop.name && prop?.options
                  ? "selected-command"
                  : ""
              }
              options={prop?.options ? true : false}
            >
              <CommandOptions>
                <CommandOptionTitle>Command Options</CommandOptionTitle>
                {prop?.options
                  ? prop.options.map((option, key) => (
                      <div key={key}>
                        <h3>
                          <span>
                            {option?.required ? "Required" : "Optional"}
                          </span>
                          {option.name}{" "}
                        </h3>
                        <p>{option.description} </p>
                      </div>
                    ))
                  : ""}
              </CommandOptions>
            </CommandBody>
          </CommandRow>
        ))}
      </CommandContainer>
    );
}

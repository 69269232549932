import Items from "./Items";
import ItemsList from "./ItemsList";

export default [
  {
    name: "Add Items",
    element: <Items />,
    path: "add-items"
  },
  {
    name: "Items List",
    element: <ItemsList />,
    path: "items"
  },
]
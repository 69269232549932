import React, { useEffect } from "react";

export default function useAPI(call, argument, callback) {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        call
          .apply(this, argument)
          .then((response) => {
            callback(response.data);
          })
          .catch((error) => {
            setError(error);
          });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      controller.abort();
    };
  }, [refresh]);

  return { error, loading, setRefresh };
}

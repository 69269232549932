import styled from "styled-components";
import device from "../../utils/media";

export const DevelopmentContainer = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  text-align: center;
  gap: 1rem;

  h2 {
    font-size: 5rem;
    text-shadow: 5px 5px var(--secondary);
  }

  p {
    font-size: 2rem;
    letter-spacing: .5rem;
  }

  @media ${device.tablet} {
    h2 {
      font-size: 2rem;
      text-shadow: 2px 2px var(--secondary);
    }

    p {
      font-size: 1rem;
      letter-spacing: .5rem;
    }
  }
`

export const GoBackButton = styled.div`
  background-color: var(--secondary);
  color: var(--white);
  border: 2px solid var(--secondary);
  padding: .5rem 2rem .5rem 2rem;
  cursor: pointer;
  :hover {
    filter: brightness(.7);
  }
` 
import React from 'react'
function Wheel() {
  return (
    <div>

    </div>
  )
}

export default Wheel

import React from "react";
import { Link } from "react-router-dom";
import { TutorialsImgs } from "../../assets";
import {
  CardBody,
  CardContainer,
  CardHeader,
  CardWarpper,
  TutorialsContainer,
  TutorialsWarpper,
} from "./styled";

export default function Tutorials() {
  return (
    <TutorialsContainer>
      <TutorialsWarpper>
        <CardContainer>
          <CardWarpper className="warpper">
            <CardHeader>
              <h3>Step 1: Adding the bot</h3>
              <p>
                The first thing you have to do is add the bot to your server,
                use the button under this text to add the bot (You need to be
                login with Discord on your browser).
              </p>
              <Link to="/invite">Invite the bot</Link>
            </CardHeader>
            <CardBody>
              <img src={TutorialsImgs.AddingTheBot} alt="" />
            </CardBody>
          </CardWarpper>
        </CardContainer>
      </TutorialsWarpper>
    </TutorialsContainer>
  );
}

import styled from "styled-components";

export const Container = styled.div``
export const Wrapper = styled.div`
  h2 {
    color: var(--white);
  }
`
export const Form = styled.form`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  input[type="file"] {
    color: var(--white);
  }
`
export const Group = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  `
export const GroupColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
`
export const IconPreview = styled.img`
  width: 130px;
  height: 130px;
  border: 2px solid var(--gray);
  background-color: var(--gray);
`


export const CardWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const Card = styled.div`
  color: var(--white);
  width: 30%;
  padding: 1rem;
  background-color: var(--primary-light);
  border-radius: 10px;
  span {
    color: var(--gray-light);
    font-size: small;
  }
  i {
    color: var(--secondary);
  }
  
  /* border: 1px solid var(--blue); */
`
export const CardHeader = styled.div`
  color: var(--gold);
  font-weight: bold;
`
export const CardMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const CardImage = styled.img`
  width: 44px;
  height: 44px;
`
export const CardContent = styled.div`

`
export const CardQuote = styled.div`
  background-color: var(--primary);
  padding: .5rem;
  margin: .5rem 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: .4rem;
  
`

export const CardActions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

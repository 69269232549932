import axios from 'axios';
const endpoint = process.env.REACT_APP_API_ENDPOINT;

function getCommands() {
    return axios.get(`https://keen-bot-api.herokuapp.com/commands`);
}



function getAllUserGuilds() {
    return axios.get(`${endpoint}/discord/guilds/all`, {
        withCredentials: true
    })
}

function getUserProfile(id) {
    return axios.get(`${endpoint}/discord/users/profile/${id}`, {
        withCredentials: true
    })
}
function getUserById(id) {
    return axios.get(`${endpoint}/discord/users/${id}`, {
        withCredentials: true
    })
}

function discordLogout() {
    return axios.get(`${endpoint}/auth/discord/logout`, {
        withCredentials: true
    })
}

function getGuildConfig(guildId) {
    return axios.get(`${endpoint}/discord/guilds/${guildId}/config`, {
        withCredentials: true
    })
}

function getGuildRoles(guildId) {
    return axios.get(`${endpoint}/discord/guilds/${guildId}/roles`, {
        withCredentials: true
    })
}

function getGuildChannels(guildId) {
    return axios.get(`${endpoint}/discord/guilds/${guildId}/channels`, {
        withCredentials: true
    })
}
function getGuildWelcome(guildId) {
    return axios.get(`${endpoint}/discord/guilds/${guildId}/welcome`, {
        withCredentials: true
    })
}
function getBackgrounds() {
    return axios.get(`${endpoint}/assets/background`, {
        withCredentials: true
    })
}



const api = { getCommands, getBackgrounds, getGuildWelcome, getAllUserGuilds, getUserById, getUserProfile, getGuildChannels, getGuildConfig, getGuildRoles, discordLogout }

export default api;
import React from "react";
import { Container, Content, Wrapper, Icon } from "./styled";

export default function Error() {
  return (
    <Container>
      <Wrapper>
        <Content>
          <h3>Something's wrong here...</h3>
          <p>
            We're sorry, but something went wrong while we connected to the
            server. Please try again later.
          </p>
        </Content>
        <Icon className="fa-solid fa-question"></Icon>
      </Wrapper>
    </Container>
  );
}

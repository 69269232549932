import styled from "styled-components";
import { motion } from "framer-motion";
import { constants } from '../../../utils'
export const GuildsWrapperContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
export const GuildsWrapperTitle = styled.h2`
  color: var(--white);
  font-size: 2rem;
  padding: 1rem;
`
export const GuildsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  @media ${constants.Device.MOBILE} {
    flex-direction: column;
  }
`
export const GuildContainer = styled(motion.div)`
  position: relative;
  width: 400px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: .5rem;
  overflow: hidden;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--primary);
    background-image: url(${(props) => props.bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(9px) brightness(.6);
  }
`
export const GuildOverview = styled.div`
  position: relative;
  height: 150px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const GuildInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`
export const GuildName = styled.div`
  font-weight: bold;
  color: var(--white);
`
export const GuildButtons = styled.div``

import React, { useEffect } from "react";
import { Container, Content, Header, Wrapper } from "./styled";

export default function Redirect({ uri }) {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = uri;
    }, 2000);
  }, [uri]);

  return (
    <Container>
      <Wrapper>
        <Header>
          <i class="fa-solid fa-signs-post"></i>
          <h3>Redirecting..</h3>
        </Header>
        <Content>
          <span title={uri}>{uri?.substr(0, 40)}...</span>
        </Content>
      </Wrapper>
    </Container>
  );
}

import { Link } from "react-router-dom";
import styled from "styled-components";
import device from "../../utils/media";

export const Container = styled.div`
  position: relative;
  margin-bottom: 3rem;
`
export const Svg = styled.svg`
  height: fit-content;
  transform: translateY(4px);
  margin: 0;
  path {
    filter: drop-shadow( 0px -4px var(--secondary));
    fill: var(--primary);
  }
`
export const FooterBody = styled.div`
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media ${device.tablet} {
    flex-direction: column;
    gap: 5rem;
  }
`

export const Rights = styled.div`
  color: var(--white);
  span {
    color: var(--light-gray);
  }
  h1 {
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
    color: var(--secondary);
    font-weight: bold;
  }
`

export const Links = styled.div`
  display: flex;
  gap: 5rem;
  color: var(--white);
  font-size: .9rem;
  span {
    line-height: 3rem;
    font-weight: bold;
  }
  @media ${device.tablet} {
    flex-direction: column;
    gap: 2rem;
  }
`

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const LinkElement = styled(Link)`
  color: var(--light-gray);
  text-decoration: none;
  transition: 300ms;
  :hover {
    color: var(--secondary);
  }
`
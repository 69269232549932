import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "../../../../api";
import { getGuildConfig, getGuildRoles } from "../../../../api/get";
import { putGuildConfig } from "../../../../api/put";
import { Loading, UnsavedAlert, useCurrentGuild } from "../../../../components";
import { ScreenContainer, SelectView } from "../Components";
import { FormWrapper } from "../styled";

export default function Welcome() {
  const [data, setData] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [form, setForm] = React.useState({
    joinRoles: [],
    state: false,
  });
  const { guild } = useCurrentGuild();
  const navigate = useNavigate();

  const { error, loading, setRefresh } = useAPI(
    getGuildRoles,
    [guild?.id],
    (response) => {
      setData(response);
    }
  );
  const { error: errorConfig, setRefresh: setRefreshConfig } = useAPI(
    getGuildConfig,
    [guild?.id],
    (response) => {
      if (response) setForm(response?.welcome);
    }
  );

  if (error || errorConfig) navigate("/error");

  const onChange = (e) => {
    setShowAlert(true);
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await putGuildConfig(guild?.id, "welcome", form)
        .then(() => {
          toast.success("Saved!");
        })
        .catch((response) => {
          toast.error(response.message);
        });
      setShowAlert(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const onReset = () => {
    setShowAlert(false);
    setRefreshConfig(Math.random());
  };

  if (loading) return <Loading />;
  else
    return (
      <ScreenContainer
        checked={form.state}
        handleChange={(_, checked) => {
          setShowAlert(true);
          setForm({ ...form, state: checked });
        }}
        button
        onRefresh={() => setRefresh(Math.random())}
      >
        <FormWrapper onSubmit={onSubmit} onReset={onReset}>
          <SelectView
            multiple
            title="Give a role to new users"
            description="Select a role to give to new users when they join the server."
            name="joinRoles"
            onChange={onChange}
            label="Select roles"
            value={form.joinRoles}
            options={
              data.length
                ? data?.map((x) => ({ value: x.id, label: `@${x.name}` }))
                : []
            }
          />
          <UnsavedAlert show={showAlert} setShow={setShowAlert} />
        </FormWrapper>
      </ScreenContainer>
    );
}

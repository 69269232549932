import { Button, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAPI from "../../../../api";
import { getGuildChannels } from "../../../../api/get";
import { putChannelMessage } from "../../../../api/put";
import { Loading, useCurrentGuild } from "../../../../components";
import {
  EmptyView,
  ScreenContainer,
  SelectView,
  TextFieldView,
} from "../Components";
import { FormWrapper } from "../styled";
import { ColorPicker, Group, InputContainer } from "./styled";

export default function Embed() {
  const initialization = {
    channelId: "",
    content: "",
    embeds: [
      {
        color: 0,
        title: "",
        url: "",
        author: {
          name: "",
          url: "",
          icon_url: "",
        },
        description: "",
        thumbnail: {
          url: "",
        },
        timestamp: "",
        footer: {
          text: "",
          icon_url: "",
        },
        image: {
          url: "",
        },
      },
    ],
  };
  const { guild } = useCurrentGuild();
  const [form, setForm] = React.useState(initialization);
  const [embed, setEmbed] = React.useState(initialization.embeds[0]);
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();

  const { error, loading, setRefresh } = useAPI(
    getGuildChannels,
    [guild?.id],
    (response) => {
      if (response) setData(response);
    }
  );

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      await putChannelMessage(form.channelId, form)
        .then(() => {
          toast.success("Embed sent!");
        })
        .catch((e) => {
          toast.error(e.message);
        });
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
    }
  };

  const onChange = (e) => {
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));
  };
  const onEmbedChange = (e) => {
    const name = e.target.name.split(".");
    if (name.length > 1) {
      setEmbed((state) => ({
        ...state,
        [name[0]]: { ...state[name[0]], [name[1]]: e.target.value },
      }));
    } else setEmbed((state) => ({ ...state, [name[0]]: e.target.value }));
    setForm((state) => ({ ...state, embeds: [embed] }));
  };

  if (error) navigate("/error");

  console.log(embed);

  const onRefresh = () => {
    setRefresh(Math.random());
  };

  if (loading) return <Loading />;
  else
    return (
      <ScreenContainer onRefresh={onRefresh}>
        <FormWrapper onSubmit={onSubmit}>
          <SelectView
            title="Channel to send the embed"
            description="Select the channel to send the embed to."
            label="Channel"
            name="channelId"
            value={form.channelId}
            onChange={onChange}
            options={data
              .filter((ch) => ch.type === 0)
              .map((channel) => {
                return { value: channel.id, label: `#${channel.name}` };
              })}
            leftHeader={
              <Button type="submit" color="info" variant="contained">
                Send
              </Button>
            }
          />
          <TextFieldView
            label="Content"
            name="content"
            value={form.content}
            onChange={onChange}
            title="Message Content"
            multiline
            rows={4}
          />
          <EmptyView title="Embed Builder">
            <InputContainer>
              <Group>
                <input type="file" name="author.icon_url" id="" />
                <TextField
                  label="Header"
                  color="info"
                  name="author.name"
                  value={embed.author.name}
                  fullWidth
                  onChange={onEmbedChange}
                />
                <TextField
                  label="Header Link"
                  fullWidth
                  color="info"
                  name="author.url"
                  value={embed.author.url}
                  onChange={onEmbedChange}
                />
              </Group>
              <Group>
                <ColorPicker
                  label="Title"
                  color="info"
                  name="color"
                  value={embed.color}
                  onChange={onEmbedChange}
                />
                <TextField
                  label="Title"
                  fullWidth
                  color="info"
                  name="title"
                  value={embed.title}
                  onChange={onEmbedChange}
                />
              </Group>
              <TextField
                label="Description"
                fullWidth
                color="info"
                name="description"
                value={embed.description}
                onChange={onEmbedChange}
                multiline
                rows={4}
              />
            </InputContainer>
          </EmptyView>
        </FormWrapper>
      </ScreenContainer>
    );
}

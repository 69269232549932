import styled from "styled-components";

export const SwitchButtonContainer = styled.div`
  text-align: center;
`
export const SwitchToggle = styled.div`
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 8px;
`
export const SwitchCheckbox = styled.input`
  display: none;
`
export const SwitchLabel = styled.label`
  position: relative;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;

  .inner {
    display: block;
    width: 200%;
    margin-left: ${(props) => props.checked ? '-100%' : '0'};
    right: ${(props) => props.checked && '0'};

    transition: margin 200ms ease-in-out 0s;
    :before,
    :after {
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 30px;
      color: #fff;
      font-weight: bold;
      box-sizing: border-box;
    }
    :before {
      content: "YES";
      padding-right: 20px;
      background-color: var(--green);
      color: #fff;
    }
    :after {
      content: "NO";
      padding-right: 10px;
      background-color: var(--gray);
      color: #fff;
      text-align: right;
    }
  }
  .switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: var(--white);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: ${(props) => props.checked ? '-100%' : '0'};
    right: ${(props) => props.checked ? '40px' : '0'};
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 200ms ease-in-out 0s;
  }
`
// export const SwitchButtonContainer = styled.div``